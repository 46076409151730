import React from "react";
import { ConfigProvider, DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

const Header = (_this) => {
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      _this?.filterOrderAnalytics(dateStrings[0], dateStrings[1], false);
    } else {
      _this?.filterOrderAnalytics(null, null, true);
    }
  };
  return (
    <div className="w-full flex justify-end items-center p-4 bg-stroke/70 dark:shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
      <div className="flex items-center gap-x-2">
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                activeBorderColor: "#3C50E0",
                hoverBorderColor: "#3C50E0",
                cellHoverWithRangeBg: "#3C50E0",
                activeShadow: "none",
                cellRangeBorderColor: "#3C50E0",
              },
              token: {
                colorPrimary: "#3C50E0",
              },
            },
          }}
        >
          <RangePicker format={dateFormat} onChange={onRangeChange} />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Header;
