import React, { useState, useEffect } from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import { useLocation } from "react-router-dom";
import Loader from "../../common/Loader";
import useDashboardLoading from "../../app/dashboardLoading";
import useCategoryStore from "../../app/categoryStore";
import API from "../../api";

const EditProduct = () => {
  const { state } = useLocation();
  const { loading, setLoading } = useDashboardLoading((state) => ({
    loading: state.loading,
    setLoading: state.setLoading,
  }));
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );

  const [categoryData, setCategoryData] = useState([]);

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
    }
  }, []);

  const _this = {
    state,
    categoryData,
    setCategoryData,
  };
  return (
    <>
      <DefaultLayout>
        {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
      </DefaultLayout>
    </>
  );
};

export default EditProduct;
