import React, { useEffect, useState } from "react";
import Body from "./Body";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import Loader from "../../common/Loader";

const CustomersOrders = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));

  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrders = () => {
    setLoading(true);
    API.order
      .GetOrders(authData.token)
      .then((response) => {
        if (response) {
          setOrdersData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const _this = {
    loading,
    setLoading,
    ordersData,
    setOrdersData,
  };

  return loading ? <Loader /> : <Body {..._this} />;
};

export default CustomersOrders;
