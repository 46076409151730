import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Body = (_this) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: "",
        password: "",
      });
    }
  }, [reset, isSubmitSuccessful]);
  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] max-h-[100vh] h-[100vh]">
        <div className="w-full h-full">
          <div className="w-full -z-50 h-[25%] relative">
            <img
              src="/images/login_image.jpg"
              alt="loginImage"
              className="w-full h-full object-cover object-top -z-[50]"
            />
          </div>
          <div className="z-40  h-[70%]">
            {/* <!-- Sign In Form --> */}
            <div className="h-[65%] bg-white rounded-b-3xl">
              <div className="flex flex-col items-center justify-center py-6">
                <h3 className="text-black dark:text-white font-gilroy-medium text-title-md font-medium">
                  Welcome Back
                </h3>
                <p className="text-strokedark/65 font-gilroy-light text-center">
                  Use your credentials below and login to your account
                </p>
              </div>
              <form
                className="lg:w-9/12 mx-auto"
                onSubmit={handleSubmit(_this.loginSubmit)}
              >
                <div className="p-6">
                  <div className="mb-4">
                    <label className="mb-2 block text-black dark:text-white font-gilroy-light">
                      Email <span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Email is required.
                      </span>
                    )}
                  </div>

                  <div>
                    <label className="mb-2.5 block text-black dark:text-white font-gilroy-light">
                      Password <span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="Enter password"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Password is required.
                      </span>
                    )}
                  </div>

                  <div className="mt-5 mb-5 flex items-center justify-end">
                    {/* <label
                      htmlFor="formCheckbox"
                      className="flex cursor-pointer"
                    >
                      <div className="relative pt-0">
                        <input
                          type="checkbox"
                          id="formCheckbox"
                          className="taskCheckbox sr-only"
                        />
                        <div className="box mr-3 flex h-5 w-5 items-center justify-center rounded border border-stroke dark:border-strokedark">
                          <span className="text-white opacity-0">
                            <svg
                              className="fill-current"
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.70685 0.292804C9.89455 0.480344 10 0.734667 10 0.999847C10 1.26503 9.89455 1.51935 9.70685 1.70689L4.70059 6.7072C4.51283 6.89468 4.2582 7 3.9927 7C3.72721 7 3.47258 6.89468 3.28482 6.7072L0.281063 3.70701C0.0986771 3.5184 -0.00224342 3.26578 3.785e-05 3.00357C0.00231912 2.74136 0.10762 2.49053 0.29326 2.30511C0.4789 2.11969 0.730026 2.01451 0.992551 2.01224C1.25508 2.00996 1.50799 2.11076 1.69683 2.29293L3.9927 4.58607L8.29108 0.292804C8.47884 0.105322 8.73347 0 8.99896 0C9.26446 0 9.51908 0.105322 9.70685 0.292804Z"
                                fill=""
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <p>Remember me</p>
                    </label> */}

                    <Link
                      to="/forget-password"
                      className="text-sm text-primary hover:underline font-gilroy-medium"
                    >
                      Forget password?
                    </Link>
                  </div>

                  <button className="flex w-full justify-center bg-primary p-3 text-gray hover:bg-opacity-90 text-white font-gilroy-medium uppercase">
                    Sign In
                  </button>
                  <div className="flex justify-center pt-3">
                    <span className="text-md font-gilroy-light">
                      Don't have an account?
                    </span>
                    <Link
                      to="/sign-up"
                      className="text-md font-gilroy-medium text-primary pl-1 hover:underline"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
