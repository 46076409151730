import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useAuthDataStore from "../../app/authStore";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider, Space } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";

const UserForm = (_this) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);
  return (
    <form
      className="flex flex-col gap-3"
      onSubmit={handleSubmit(_this?.updateProfileDetails)}
    >
      {/* <!-- Input Fields --> */}
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
        <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Profile Information
          </h3>
          <p className="text-sm text-strokedark/60">
            Easily input essential profile details to update to update profile
            details.
          </p>
        </div>

        <div>
          <div className="flex flex-col gap-5 p-6">
            <div>
              <label className="mb-3 block text-black dark:text-white">
                Name <span className="text-meta-1">*</span>
                {errors.name && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    Name is required
                  </span>
                )}
              </label>
              <input
                defaultValue={authData?.userData?.name}
                type="text"
                placeholder="Enter you Name"
                className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                {...register("name", { required: true })}
              />
            </div>

            <div>
              <label className="mb-3 block text-black dark:text-white">
                Email <span className="text-meta-1">*</span>
                {errors.email && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    Email is required
                  </span>
                )}
              </label>
              <input
                defaultValue={authData?.userData?.email}
                type="text"
                placeholder="Enter you Email"
                className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                {...register("email", { required: true })}
              />
            </div>
            <div className="py-5">
              <label className="mb-3 block text-black dark:text-white">
                Add Hostel <span className="text-meta-1">*</span>
              </label>
              <Space.Compact
                style={{
                  width: "100%",
                }}
              >
                <input
                  value={_this?.hostelInput}
                  type="text"
                  onChange={(e) => _this?.setHostelInput(e.target.value)}
                  placeholder="Enter your Hostel name"
                  className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                />
                <div
                  className="py-1 bg-dashboardPrimary px-3 rounded-r-md text-white flex items-center border border-dashboardPrimary"
                  onClick={_this?.onNewHostelAdd}
                  size="large"
                >
                  <AiOutlinePlusCircle size={25} />
                </div>
              </Space.Compact>

              <div className="flex flex-row items-center flex-wrap gap-2 pt-4">
                {_this?.hostels.length > 0 &&
                  _this?.hostels?.map((item) => (
                    <div className="flex items-center text-sidebarBlack text-md font-satoshi font-normal border border-black/10 px-3 py-2 rounded-md">
                      {item}
                      <IoCloseOutline
                        onClick={() => {
                          _this?.onHostelDelete(item);
                        }}
                        size={24}
                        className="ml-3 text-black/20 cursor-pointer"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="flex justify-center text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90"
        >
          {_this?.buttonLoading ? (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#ffff",
                },
              }}
            >
              <div className="flex items-center gap-x-2">
                <Spin indicator={<LoadingOutlined spin />} /> Loading...
              </div>
            </ConfigProvider>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </form>
  );
};

export default UserForm;
