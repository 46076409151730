import React from "react";
import { HiOutlineHome } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { LuPackageOpen } from "react-icons/lu";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";
import { useNavigate } from "react-router-dom";
const items = [
  {
    key: "1",
    label: (
      <div>
        <h5 className="text-sm font-gilroy-semibold">My Account</h5>
        <p className="text-xs font-light text-black/50 font-gilroy-medium">
          Address & Settings
        </p>
      </div>
    ),
    children: (
      <div className="flex flex-col gap-y-5 border-t border-dashed border-black/30 pt-4">
        <Link to="/manage-address">
          <div className="text-black/60 flex justify-between">
            <div className="flex items-center gap-x-1 font-gilroy-semibold">
              <HiOutlineHome className="text-lg" />
              <p>Manage Address</p>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>
        {/* <div className=" text-black/60 flex justify-between">
          <div className="flex items-center gap-x-1 ">
            <AiOutlineSetting className="text-lg" />
            <p>Settings</p>
          </div>
          <IoIosArrowForward />
        </div> */}
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div>
        <h5 className="text-sm font-gilroy-semibold">Orders</h5>
        <p className="text-xs font-light text-black/50 font-gilroy-medium">
          Orders & Payments
        </p>
      </div>
    ),
    children: (
      <div className="flex flex-col gap-y-5 border-t border-dashed border-black/30 pt-4">
        <Link to="/orders">
          <div className="text-black/60 flex justify-between">
            <div className="flex items-center gap-x-1 font-gilroy-semibold">
              <LuPackageOpen className="text-lg" />
              <p>Orders</p>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>
        {/* <div className=" text-black/60 flex justify-between">
          <div className="flex items-center gap-x-1 ">
            <MdOutlinePayment className="text-lg" />
            <p>Payments</p>
          </div>
          <IoIosArrowForward />
        </div> */}
      </div>
    ),
  },
];
const Body = (_this) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-center min-h-[calc(100vh-125px)] md:min-h-[calc(100vh-90px)]">
      <div className="flex flex-col w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 max-w-maxContent gap-y-4 py-3">
        <div className="w-full">
          <div className="w-full flex justify-between mt-4">
            <div className="flex items-center gap-x-4">
              <div
                className="flex justify-center mb-1"
                onClick={() => navigate("/")}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  aria-hidden="true"
                  strokecolor="rgba(2, 6, 12, 0.92)"
                  fillcolor="rgba(2, 6, 12, 0.6)"
                >
                  <path
                    d="M9.35613 3.45006C9.7599 3.07325 10.3927 3.09511 10.7695 3.49888C11.1463 3.90266 11.1245 4.53544 10.7207 4.91225L5.89328 9.41726C5.65564 9.63903 5.45297 9.82863 5.27991 9.99493L18.9938 9.99493C19.5461 9.99493 19.9938 10.4426 19.9938 10.9949C19.9938 11.5472 19.5461 11.9949 18.9938 11.9949L5.2162 11.9949C5.4027 12.1757 5.62584 12.3845 5.89326 12.6341L10.6626 17.085C11.0664 17.4618 11.0882 18.0946 10.7114 18.4984C10.3346 18.9021 9.7018 18.924 9.29803 18.5472L4.48094 14.0517C3.93464 13.5419 3.45138 13.091 3.1139 12.6762C2.74838 12.2269 2.44919 11.6972 2.44919 11.0257C2.44919 10.3541 2.74839 9.82444 3.11392 9.37513C3.4514 8.96029 3.93465 8.50938 4.48095 7.99965L9.35613 3.45006Z"
                    fill="rgba(2, 6, 12, 0.6)"
                    fillOpacity="0.92"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <h4 className="text-base font-gilroy-bold font-semibold uppercase text-black/80">
                  {authData?.userData?.name}
                </h4>
                <p className="text-xs text-subtext font-gilroy-semibold">
                  {authData?.userData?.phone_number}
                </p>
              </div>
            </div>
            <Link
              to="/edit-profile"
              className="text-base font-bold text-primary font-gilroy-bold"
            >
              Edit
            </Link>
          </div>
          <div className="h-[2px] bg-black/50 mt-3"></div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  headerBg: "#ffff",
                  headerPadding: "16px",
                },
              },
              token: {
                fontSize: 15,
              },
            }}
          >
            <Collapse
              accordion
              bordered={false}
              items={items}
              expandIconPosition="end"
              size="middle"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            />
          </ConfigProvider>
          <div className="h-[1px] bg-black/10 mt-0.5"></div>
        </div>
        <button
          onClick={() => _this.logOut()}
          className="w-full text-base font-bold text-white bg-primary rounded-md py-1.5 sm:py-2 mt-5"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Body;
