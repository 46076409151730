import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { generateColors } from "../../utils";

const ChartThree = (_this) => {
  const labels = _this?.categoryAnalytics.map((data) => data.category);
  const data = _this?.categoryAnalytics.map((data) => data.totalSales);
  const colors = generateColors(labels.length);
  const [series, setSeries] = useState(data); // Adjusted initial state
  // ["#3C50E0", "#6577F3", "#8FD0EF", "#0FADCF"]
  const options = {
    chart: {
      fontFamily: "Satoshi, sans-serif",
      type: "donut",
    },
    colors: colors,
    labels: labels,
    legend: {
      show: false,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <div className="sm:px-7 divide-y col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4 h-full w-full">
      <div className="mb-3 justify-between gap-4 sm:fle">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Category Analytics
          </h5>
        </div>
      </div>

      <div className="mb-2 h-full w-full">
        <div
          id="chartThree"
          className="flex justify-center items-center h-full w-full"
        >
          <ReactApexChart options={options} series={series} type="donut" />
        </div>
      </div>

      {/* <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        {_this?.categoryAnalytics.map((data, index) => {
          return (
            <div className="sm:w-1/2 w-full px-8" key={index}>
              <div className="flex w-full items-center">
                <span
                  className={`mr-2 block h-3 w-full max-w-3 rounded-full bg-dashboardPrimary`}
                ></span>
                <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
                  <span>{data?.category}</span>
                  <span>
                    {(Math.round(data?.percentage * 100) / 100).toFixed(2)}%
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default ChartThree;
