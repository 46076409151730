import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useForm } from "react-hook-form";
import useAuthDataStore from "../../app/authStore";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider } from "antd";
import OtpInput from "react-otp-input";

const Body = (_this) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  const [otp, setOtp] = useState(_this.OTP);

  useEffect(() => {
    _this.setOTP(otp);
  }, [otp]);
  return (
    <>
      <div className="flex flex-col items-center relative">
        <div
          className={`w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] ${
            _this.ordersData && _this.ordersData.length === 0
              ? "bg-white "
              : "bg-cartBg/60 "
          } relative`}
        >
          <Navbar />

          <div className="h-full flex flex-col justify-center items-center my-7 px-4 gap-y-6">
            {/* <!-- Name In Form --> */}
            <div className=" bg-white w-full lg:w-10/12 mx-auto border">
              <form
                className=""
                onSubmit={handleSubmit(_this?.onChangeCustomerDetails)}
              >
                <div className="p-6">
                  <div className="mb-4">
                    <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                      Name <span className="text-meta-1">*</span>{" "}
                      {errors.hostel_name && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Name is required.
                        </span>
                      )}
                    </label>
                    <input
                      defaultValue={authData?.userData?.name}
                      type="text"
                      placeholder="Enter your hostel name"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                      {...register("name", { required: true })}
                    />
                  </div>

                  <button
                    type="submit"
                    className="mt-5 flex w-full justify-center bg-primary p-3 text-gray hover:bg-opacity-90 text-white font-gilroy-medium uppercase"
                  >
                    {_this?.buttonLoading ? (
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#ffff",
                          },
                        }}
                      >
                        <Spin indicator={<LoadingOutlined spin />} />
                      </ConfigProvider>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>

            {/* <!-- Email In Form --> */}
            <div className=" bg-white w-full lg:w-10/12 mx-auto border">
              <form className="" onSubmit={handleSubmit(_this?.onChangeEmail)}>
                <div className="p-6">
                  <div className="mb-4">
                    <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                      Email <span className="text-meta-1">*</span>{" "}
                      {errors.hostel_name && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Email is required.
                        </span>
                      )}
                    </label>
                    <input
                      defaultValue={authData?.userData?.email}
                      type="text"
                      placeholder="Enter your hostel name"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                      {...register("email", { required: true })}
                    />
                  </div>

                  {_this?.otpReceived && (
                    <div className="flex flex-col justify-center items-start mt-4 mb-2 gap-y-2">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputType="tel"
                        inputStyle={{
                          height: "3rem",
                          width: "3rem",
                          border: "1px solid #ed1c24",
                          borderRadius: "5px",
                          padding: "5px",
                          margin: "0px 10px",
                          outline: "none",
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                      <p className="text-sm text-primary pl-3">
                        Otp send to your const name new entered email id
                      </p>
                    </div>
                  )}

                  <button className="mt-5 flex w-full justify-center bg-primary p-3 text-gray hover:bg-opacity-90 text-white font-gilroy-medium uppercase">
                    {_this?.buttonLoading ? (
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#ffff",
                          },
                        }}
                      >
                        <Spin indicator={<LoadingOutlined spin />} />
                      </ConfigProvider>
                    ) : (
                      <div>
                        {_this?.otpReceived ? <p>Verify</p> : <p>Send Otp</p>}
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
