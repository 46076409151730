import React from "react";
import { Link } from "react-router-dom";
import useCartDataStore from "../../app/cartStore";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const CartContain = (_this) => {
  const {
    cartData,
    totalAmount,
    totalItem,
    discountAmount,
    clearCart,
    increaseQuantity,
    decreaseQuantity,
  } = useCartDataStore((state) => ({
    cartData: state.cartData,
    totalAmount: state.totalAmount,
    totalItem: state.totalItem,
    discountAmount: state.discountAmount,
    clearCart: state.clearCart,
    increaseQuantity: state.increaseQuantity,
    decreaseQuantity: state.decreaseQuantity,
  }));

  console.log(cartData);

  return (
    <div className="p-[16px] pb-48 sm:pb-28">
      <div className="flex justify-between">
        <div className="mb-[12px] ml-[4px]">
          <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
            Review Items
          </p>
        </div>
        <div className="ml-[4px] mb-[12px]">
          <button
            onClick={() => clearCart()}
            className="flex gap-2 items-center"
          >
            <p className="text-[14px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060c99]">
              Clear cart
            </p>
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                aria-hidden="true"
                strokecolor="rgba(2, 6, 12, 0.6)"
                fillcolor="rgba(2, 6, 12, 0.6)"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.9019 1.99976C6.60383 1.99976 6.33 2.16396 6.1896 2.4269L5.92919 2.91458H2.7C2.3134 2.91458 2 3.22798 2 3.61458C2 4.00118 2.3134 4.31458 2.7 4.31458H13.408C13.7946 4.31458 14.108 4.00118 14.108 3.61458C14.108 3.22798 13.7946 2.91458 13.408 2.91458H9.99774L9.84622 2.51864C9.72663 2.20613 9.42668 1.99976 9.09207 1.99976H6.9019Z"
                  fill="rgba(2, 6, 12, 0.6)"
                  fillOpacity="0.92"
                ></path>
                <path
                  d="M3.80865 5.82324L4.49033 11.6629C4.58535 12.4769 5.27493 13.0907 6.09442 13.0907H10.0071C10.8097 13.0907 11.4906 12.5012 11.6054 11.7068L12.4562 5.82324"
                  stroke="rgba(2, 6, 12, 0.6)"
                  strokeOpacity="0.92"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M6.854 7.03442L6.854 10.2644"
                  stroke="rgba(2, 6, 12, 0.6)"
                  strokeOpacity="0.92"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M9.27657 7.03442L9.27657 10.2644"
                  stroke="rgba(2, 6, 12, 0.6)"
                  strokeOpacity="0.92"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                ></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div>
        <div className="transform z-1px bg-white rounded-[16px]">
          <div className="py-[16px] gap-[16px] flex flex-col">
            <div className="gap-[16px] flex flex-col">
              {cartData.map((item, index) => {
                return (
                  <div className="px-[12px] flex items-center" key={index}>
                    <div className="w-[44px] h-[44px] rounded-[4px] overflow-hidden bg-[#f0f0f5]">
                      <img
                        src={`${BASE_URL}${item?.product?.product_image[0]}`}
                        alt="cart_image"
                        className="h-full w-full object-fill"
                      />
                    </div>
                    <div className="flex-1 ml-[12px] mr-[8px] overflow-hidden">
                      <p className="text-[14px] font-gilroy-semibold leading-4 font-normal text-[#02060cbf] tracking-[-0.3px]">
                        {item?.product?.product_name}
                      </p>
                      <p className="text-[10px] font-gilroy-semibold leading-3 font-normal text-[#02060c73] tracking-[-0.25px]">
                        {item?.product?.product_description1}
                      </p>
                    </div>
                    <div className="w-[76px] border py-2 rounded-lg shadow-1 flex justify-around items-center">
                      <button
                        onClick={() => decreaseQuantity(item?.product?._id)}
                        className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                      >
                        -
                      </button>
                      <div className="text-[14px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]">
                        {item?.quantity}
                      </div>
                      <button
                        onClick={() => increaseQuantity(item?.product?._id)}
                        className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                      >
                        +
                      </button>
                    </div>
                    <div className="h-[32px] w-[50px] flex flex-col justify-end items-end">
                      <p className="rs line-through mt-[1px] font-gilroy-semibold text-[10px] leading-3 tracking-[-0.26px] text-[#02060c73]">
                        {item?.product?.product_price}
                      </p>
                      <p className="rs mt-[1px] font-gilroy-bold text-[14px] leading-[18px] font-semibold tracking-[-0.3px] text-[#02060cbf]">
                        {item?.product?.discounted_price}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pt-[24px] pr-[16px] pb-[12px] pl-[12px] bg-[#eee9ff] border-[1px] border-white rounded-b-[16px] mt-[-12px] mb-[24px] flex justify-center items-center">
          <div className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] realtive mb-[2px]">
            <img
              src="/images/cart_tick.avif"
              alt="cart_tick"
              className="w-full h-full object-fill bg-transparent"
            />
          </div>
          <div className="text-[17px] leading-6 tracking-[-0.44px] font-normal font-gilroy-semibold text-[#02060cbf]">
            Missed Something?{" "}
            <Link
              to="/"
              className="text-[17px] leading-6 tracking-[-0.44px] font-normal font-gilroy-bold text-[#6541e4]"
            >
              Search
            </Link>{" "}
            or{" "}
            <Link
              to="/"
              className="text-[17px] leading-6 tracking-[-0.44px] font-normal font-gilroy-bold text-[#6541e4]"
            >
              Browse Store
            </Link>
          </div>
        </div>
      </div>

      <div className="flex justify-start">
        <div className="mb-[12px] ml-[4px]">
          <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
            Bill Details
          </p>
        </div>
      </div>
      <div className="transform z-1px bg-white rounded-[16px] shadow-custom mb-[24px]">
        <div className="p-[16px] gap-[16px] flex flex-col">
          <div className="gap-[16px] flex flex-col">
            <div className="flex items-center justify-between mb-[12px]">
              <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                MRP Total
              </div>
              <div className="rs text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                {totalAmount}
              </div>
            </div>
            <div className="flex items-center justify-between pb-[16px] border-b border-dashed">
              <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                Item Saving
              </div>
              <div className="-rs text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#1ba672] break-words">
                {totalAmount - discountAmount}
              </div>
            </div>
            <div className=" flex items-center justify-between pt-[12px]">
              <div className="text-[14px] font-gilroy-bold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                Total Pay
              </div>
              <div className="flex items-center gap-x-1">
                <p className="rs text-[13px] line-through font-gilroy-medium leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {totalAmount}
                </p>
                <p className="rs text-[14px] font-gilroy-bold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {discountAmount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-start">
        <div className="mb-[12px] ml-[4px]">
          <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
            Select Payment
          </p>
        </div>
      </div>
      <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
        <div className="p-[16px] gap-[16px] flex flex-col">
          <div className="gap-[16px] flex flex-col">
            <div className="flex items-center ps-4 border border-gray-200 rounded-[16px] dark:border-gray-700">
              <input
                id="Online"
                type="radio"
                value="Online"
                name="payment_method"
                className="w-4 h-4 text-primary bg-gray-100 border-gray-300 "
                onChange={(e) => {
                  _this?.setPaymentMod(e.target.value);
                }}
                checked={_this?.paymentMod === "Online" ? true : false}
              />
              <label
                htmlFor="Online"
                className="w-full py-4 ms-2 text-[14px] font-gilroy-medium leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]"
              >
                Online
              </label>
            </div>
            <div className="flex items-center ps-4 border border-gray-200 rounded-[16px] dark:border-gray-700">
              <input
                id="COD"
                type="radio"
                value="COD"
                name="payment_method"
                className="w-4 h-4 text-primary bg-gray-100 border-gray-300"
                onChange={(e) => {
                  _this?.setPaymentMod(e.target.value);
                }}
                checked={_this?.paymentMod === "COD" ? true : false}
              />
              <label
                htmlFor="COD"
                className="w-full py-4 ms-2 text-[14px] font-gilroy-medium leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]"
              >
                Pay on Delivery
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartContain;
