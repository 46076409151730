import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider } from "antd";

const ChangePassword = (_this) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);
  return (
    <form
      className="flex flex-col gap-3"
      onSubmit={handleSubmit(_this?.changePassword)}
    >
      {/* <!-- Input Fields --> */}
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
        <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Change Password
          </h3>
          <p className="text-sm text-strokedark/60">
            Enter you old password new and confirm password.
          </p>
        </div>

        <div>
          <div className="flex flex-col gap-5 p-6">
            <div>
              <label className="mb-3 block text-black dark:text-white">
                Old Password <span className="text-meta-1">*</span>
                {errors.old_password && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    Old password is required
                  </span>
                )}
              </label>
              <input
                type="password"
                placeholder="Enter you Old Password"
                className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                {...register("old_password", { required: true })}
              />
            </div>
            <div>
              <label className="mb-3 block text-black dark:text-white">
                New Password <span className="text-meta-1">*</span>
                {errors.new_password && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    New password is required
                  </span>
                )}
              </label>
              <input
                type="password"
                placeholder="Enter you New Password"
                className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                {...register("new_password", { required: true })}
              />
            </div>
            <div>
              <label className="mb-3 block text-black dark:text-white">
                Confirm Password <span className="text-meta-1">*</span>
                {errors.confirm_password && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    Confirm password is required
                  </span>
                )}
              </label>
              <input
                type="password"
                placeholder="Enter you Confirm Password"
                className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                {...register("confirm_password", { required: true })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button className="flex justify-center text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90">
          {_this?.buttonLoading ? (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#ffff",
                },
              }}
            >
              <div className="flex items-center gap-x-2">
                <Spin indicator={<LoadingOutlined spin />} /> Loading...
              </div>
            </ConfigProvider>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
