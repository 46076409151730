import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const OrdersList = (_this) => {
  return (
    <div className="flex flex-col gap-y-4 h-full">
      {_this.ordersData &&
        _this.ordersData.map((order, index) => {
          return (
            <Link to={`/order/${order._id}`} state={order} key={index}>
              <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
                <div className="p-[16px] gap-[16px] flex flex-col">
                  <div className="gap-[16px] flex flex-col">
                    <div className="flex items-center justify-between mb-[12px]">
                      <div>
                        <h5 className="text-[16px] font-gilroy-bold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                          {order.order_status}
                        </h5>
                        <p className="text-[14px] mt-2 font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                          {moment(order.order_date).format(
                            "Do MMMM YYYY, h:mm a"
                          )}
                        </p>
                      </div>
                      <p
                        className={`text-sm font-gilroy-semibold  ${
                          order.order_status === "Cancelled"
                            ? "text-black/60"
                            : "text-[#1ba672]"
                        } font-semibold
                flex items-center gap-2`}
                      >
                        {" "}
                        View Order
                        <FaArrowRight />
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-x-3">
                      {order.order_items.slice(0, 3).map((items) => {
                        return (
                          <div key={items._id}>
                            <img
                              alt="product_image"
                              src={`${
                                BASE_URL + items?.product?.product_image
                              }`}
                              className={`h-12 xs:h-14 max-h-16 object-cover rounded-md ${
                                order.order_status === "Cancelled" &&
                                "grayscale"
                              }`}
                            />
                          </div>
                        );
                      })}
                      {order.order_items.length > 3 && (
                        <p className="text-xs font-light text-black/65">more</p>
                      )}
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default OrdersList;
