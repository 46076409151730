import React from "react";
import { useNavigate } from "react-router-dom";

const EmptyOrder = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[calc(100vh-64px)] flex justify-center items-center">
      <div className="flex items-center">
        <div className="flex flex-col items-center">
          <div className="h-[220px] w-[220px]">
            <img
              src="/images/Empty_Cart_State.avif"
              className="w-full h-full"
            />
          </div>
          <div className="pt-[16px]">
            <p className="font-gilroy-bold text-[24px] leading-7 text-[#02060ceb] -tracking-[0.4]">
              Your cart is getting lonely
            </p>
            <p className="text-[#02060c99] font-gilroy-semibold text-[13px] leading-4 text-center mt-[8px]">
              Fill it up with all things good!
            </p>
            <div className="mt-[28px] flex justify-center">
              <button
                onClick={() => navigate("/")}
                className="bg-[#ffeee5] px-[16px] h-[44px] rounded-[12px] text-[#f15700] font-gilroy-bold text-[18px] leading-6 -tracking-[0.3]"
              >
                Start Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyOrder;
