import React from "react";
import Navbar from "./Navbar";
import OrderDetails from "./OrderDetails";

const Body = (_this) => {
  return (
    <>
      <div className="flex flex-col items-center relative">
        <div
          className={`w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] bg-cartBg/60 relative`}
        >
          <Navbar {..._this} />
          <div className="my-5 mx-5">
            <OrderDetails {..._this} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
