import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, Modal } from "antd";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import useProductStore from "../../app/productStore";
import useCategoryStore from "../../app/categoryStore";
import useAuthDataStore from "../../app/authStore";
import useDashboardLoading from "../../app/dashboardLoading";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Body = (_this) => {
  const { globalProductData, setGlobalProductData } = useProductStore(
    (state) => ({
      globalProductData: state.globalProductData,
      setGlobalProductData: state.setGlobalProductData,
    })
  );
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { setLoading } = useDashboardLoading((state) => ({
    setLoading: state.setLoading,
  }));
  const navigate = useNavigate();
  // const [imageError, setImageError] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    _this?.state?.product_category?._id
  );
  const [subcategoryAvailable, setSubcategoryAvailable] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [priceInput, setPriceInput] = useState(_this?.state?.product_price);
  const [discountedPrice, setDiscountedPrice] = useState(
    _this?.state?.discounted_price
  );
  const [discountedPercentage, setDiscountedPercentage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  /* Upload is Functionality */
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const fileProps = {
    action: "",
    name: "file",
    multiple: true,
    customRequest(e) {
      e.onSuccess();
    },
  };
  // useEffect(() => {
  //   if (fileList.length > 0) {
  //     setImageError(false);
  //   }
  // }, [fileList]);

  /* Form is Functionality */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        category_name: "",
        category_description: "",
      });
    }
  }, [reset, isSubmitSuccessful]);

  /*==================================
            Update Product
    =====================================
    */
  const onUpdateProduct = (formValue) => {
    let formData = new FormData();
    formData.append("product_id", _this?.state?._id);
    formData.append("product_name", formValue.product_name);
    formData.append("product_description1", formValue.product_description1);
    formData.append("product_description2", formValue.product_description2);
    formData.append("product_price", formValue.product_price);
    formData.append("discounted_price", formValue.discounted_price);
    formData.append("product_category", formValue.product_category);
    if (formValue.product_subcategory) {
      formData.append("product_subcategory", formValue.product_subcategory);
    }
    formData.append("product_quantity", formValue.product_inventory);
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        formData.append("product_image_file", fileList[i]?.originFileObj);
      }
    }

    setLoading(true);
    API.product
      .UpdateProduct(authData.token, formData)
      .then((response) => {
        if (response) {
          toast.success("Product Updated successfully");
          navigate("/dashboard/manage-product");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* Calculate Discounted Price */
  const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    if (
      !originalPrice ||
      !discountedPrice ||
      originalPrice <= 0 ||
      discountedPrice < 0
    ) {
      return "";
    }
    const discount = ((originalPrice - discountedPrice) / originalPrice) * 100;
    setDiscountedPercentage(discount.toFixed(2).toString());
  };

  useEffect(() => {
    if (priceInput !== "" && discountedPrice !== "") {
      calculateDiscountPercentage(
        parseFloat(priceInput),
        parseFloat(discountedPrice)
      );
    }
  }, [priceInput, discountedPrice]);
  return (
    <>
      <div className="p-4 md:p-6 2xl:p-10">
        <form
          onSubmit={handleSubmit(onUpdateProduct)}
          className="flex flex-col gap-3"
        >
          {/* <!-- Input Fields --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Edit Product Information
              </h3>
              <p className="text-sm text-strokedark/60">
                Easily input essential details like name, price, and more to
                showcase your product.
              </p>
            </div>

            <div>
              <div className="flex flex-col gap-5 p-6">
                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Product Name <span className="text-meta-1">*</span>
                    {errors.product_name && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Product name is required.
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    defaultValue={_this?.state?.product_name}
                    placeholder="Product Name"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("product_name", { required: true })}
                  />
                </div>

                <div className="mb-4 flex flex-col gap-6 xl:flex-row w-full">
                  <div
                    className={`${subcategoryAvailable && "w-[50%]"} w-[49%]`}
                  >
                    <label
                      htmlFor="product_category"
                      className="mb-2.5 block text-black dark:text-white"
                    >
                      Category <span className="text-meta-1">*</span>
                      {errors.product_category && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Product Category is required.
                        </span>
                      )}
                    </label>

                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <select
                        name="product_category"
                        {...register("product_category", {
                          value: selectedCategoryId,
                          required: true,
                        })}
                        value={selectedCategoryId}
                        onChange={(e) => {
                          setValue("product_category", e.target.value);
                          setSelectedCategoryId(e.target.value);
                          setIsCategorySelected(true);
                          const category = _this?.categoryData.find(
                            (category) => category._id === e.target.value
                          );
                          if (category?.subcategories.length > 0) {
                            setSubcategoryAvailable(true);
                          }
                        }}
                        className={`relative z-20 w-full appearance-none border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                          isCategorySelected ? "text-black dark:text-white" : ""
                        }`}
                      >
                        <option
                          value=""
                          disabled
                          className="text-body dark:text-bodydark"
                        >
                          Select your Category
                        </option>
                        {_this?.categoryData.map((category, index) => {
                          return (
                            <option
                              key={index}
                              value={category?._id}
                              className="text-body dark:text-bodydark"
                            >
                              {category?.category_name}
                            </option>
                          );
                        })}
                      </select>

                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div
                    className={`${
                      subcategoryAvailable ? "flex" : "hidden"
                    } w-[50%]`}
                  >
                    <label className="mb-2.5 block text-black dark:text-white">
                      Sub Category <span className="text-meta-1">*</span>{" "}
                    </label>

                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <select
                        {...register("product_subcategory")}
                        // onChange={(e) => {
                        //   setIsCategorySelected(true);
                        //   const category = _this?.categoryData.find(
                        //     (category) => category._id === e.target.value
                        //   );
                        //   if (category?.subcategories.length > 0) {
                        //     setSubcategoryAvailable(true);
                        //   }
                        // }}
                        className={`relative z-20 w-full appearance-none border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                          isCategorySelected ? "text-black dark:text-white" : ""
                        }`}
                      >
                        <option
                          value=""
                          disabled
                          className="text-body dark:text-bodydark"
                        >
                          Select your sub category
                        </option>
                        {_this?.categoryData?.subcategories?.map(
                          (subcategory, index) => {
                            return (
                              <option
                                key={index}
                                value={subcategory?._id}
                                className="text-body dark:text-bodydark"
                              >
                                {subcategory?.subcategory_name}
                              </option>
                            );
                          }
                        )}
                      </select>

                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mb-4 flex flex-col gap-6 xl:flex-row relative">
                  <div
                    className={`${
                      priceInput === "" || discountedPrice === ""
                        ? "hidden"
                        : "flex"
                    } absolute right-3 bg-[#ee741f]`}
                  >
                    <p className="text-[12px] leading-4 font-satoshi py-[2px] px-[8px] rounded-[2px] text-white">
                      {discountedPercentage}% OFF
                    </p>
                  </div>
                  <div className="w-full xl:w-1/2">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Price <span className="text-meta-1">*</span>
                      {errors.product_price && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Product price is required.
                        </span>
                      )}
                    </label>
                    <input
                      defaultValue={_this?.state?.product_price}
                      {...register("product_price", { required: true })}
                      onChange={(e) => {
                        setPriceInput(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter price"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    />
                  </div>

                  <div className="w-full xl:w-1/2">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Discounted Price <span className="text-meta-1">*</span>
                      {errors.discounted_price && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Discounted price is required.
                        </span>
                      )}
                    </label>
                    <input
                      defaultValue={_this?.state?.discounted_price}
                      {...register("discounted_price", { required: true })}
                      onChange={(e) => {
                        setDiscountedPrice(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter discounted price"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    />
                  </div>
                </div>
                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Product Inventory <span className="text-meta-1">*</span>
                    {errors.product_inventory && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Product inventory is required.
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    defaultValue={_this?.state?.product_quantity}
                    {...register("product_inventory", { required: true })}
                    placeholder="Product Inventory"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                  />
                </div>

                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Product Description 1 <span className="text-meta-1">*</span>
                    {errors.product_description1 && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Product Description1 is required.
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    defaultValue={_this?.state?.product_description1}
                    {...register("product_description1", { required: true })}
                    placeholder="Product Description"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                  />
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Product Description 2 <span className="text-meta-1">*</span>
                    {errors.product_description2 && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Product description is required.
                      </span>
                    )}
                  </label>
                  <textarea
                    defaultValue={_this?.state?.product_description2}
                    {...register("product_description2", { required: true })}
                    rows={6}
                    placeholder="Type your product description"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Edit Product Images <span className="text-meta-1">*</span>
              </h3>
              <p className="text-sm text-strokedark/60">
                Upload captivating images and videos to make your product stand
                out.
              </p>
            </div>

            <div>
              <div className="flex flex-col gap-5 p-6">
                <Upload
                  action="#"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  {...fileProps}
                >
                  {fileList.length >= 4 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
                {/* {imageError === true && (
                  <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                    Category image is required.
                  </span>
                )} */}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              // onClick={() => console.log("hell")}
              className="flex justify-center text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90"
            >
              Edit Product
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Body;
