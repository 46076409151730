import React from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProductList = (_this) => {
  return (
    <div className="w-[80%] pb-[80px] rounded-br-none rounded-tr-none rounded-tl-[8px] bg-white min-h-[calc(100vh-68px)] max-h-[calc(100vh-68px)] overflow-y-scroll no-scrollbar">
      {_this?.productData?.length === 0 ? (
        <div className="w-full h-full">
          <div className="flex justify-center items-center h-full font-gilroy-medium text-md text-[rgba(2,6,12,0.75)]">
            <div className="flex flex-col items-center">
              <img
                src="/images/Empty_Cart_State.avif"
                className="w-[100px] h-[100px]"
              />
              <p>No product added yet !</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 divide-x-[1px] divide-y-[1px]">
          {_this?.productData &&
            _this?.productData?.map((product, index) => {
              return (
                <div className="relative p-[8px]" key={index}>
                  <div className="flex items-center justify-center py-5">
                    <div className=" w-[120px] h-[120px] ">
                      <img
                        src={`${BASE_URL}${product?.product_image[0]}`}
                        alt="product_image"
                        className={`h-full w-full ${
                          (!product.universal_availability ||
                            !_this?.category?.isAvailable ||
                            !product?.is_available) &&
                          "grayscale"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="mt-2 truncate font-gilroy-bold font-bold text-[14px] leading-[18px] tracking-[-0.3px] text-[rgba(2,6,12,0.75)] overflow-hidden w-full break-words min-h-[36px]">
                    {product?.product_name}
                  </div>
                  <div className="font-gilroy-medium text-[rgba(2,6,12,0.75)] block text-[12px] leading-[16px] p-0 border-0 outline-none overflow-hidden whitespace-nowrap ">
                    {product?.product_description1}
                  </div>
                  <div className="mt-[8px] flex justify-between">
                    <div className="flex flex-col justify-center">
                      <span className="text-[10px] font-gilroy-light rs line-through">
                        {product?.product_price}
                      </span>
                      <p className="rs font-gilroy-bold font-bold text-[14px] leading-[18px] tracking-[-0.3px] text-[rgba(2,6,12,0.75)] overflow-hidden w-full break-words ">
                        {product?.discounted_price}
                      </p>
                    </div>
                    <div className="flex items-center transition-container">
                      {_this?.isProductInCart(product?._id) ? (
                        <div className="w-[76px] border py-2 rounded-lg shadow-1 flex justify-around items-center">
                          <button
                            onClick={() => _this?.handleDecrease(product?._id)}
                            className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                          >
                            -
                          </button>
                          <div className="text-[14px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]">
                            {_this?.isProductInCart(product?._id)}
                          </div>
                          <button
                            onClick={() => _this?.handleIncrease(product?._id)}
                            className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!product.universal_availability ||
                          !_this?.category?.isAvailable ||
                          !product?.is_available ? (
                            <div className="w-[100px] ">
                              <p
                                className={` font-gilroy-semibold text-[14px] leading-[17px] tracking-[-0.1px] text-[rgba(2,6,12,0.75)]`}
                              >
                                Not Available
                              </p>
                            </div>
                          ) : (
                            <button
                              onClick={() => _this?.handleAddToCart(product)}
                              className="w-[76px] border py-2 rounded-lg shadow-1 hover:bg-strokedark/10"
                            >
                              <p
                                className={`font-gilroy-bold text-[14px] leading-[17px] tracking-[-0.1px] text-[#1ba672]`}
                              >
                                Add
                              </p>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ProductList;
