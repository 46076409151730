import React, { useState } from "react";
import Body from "./Body";
import Loader from "../../common/Loader";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { setCookie } from "../../utils";
import useAuthDataStore from "../../app/authStore";

const Login = () => {
  const { setAuthData } = useAuthDataStore((state) => ({
    setAuthData: state.setAuthData,
  }));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loginSubmit = async (data) => {
    setLoading(true);
    API.auth
      .Login(data)
      .then((response) => {
        if (response) {
          setCookie(
            "authentications",
            { token: response.token, userData: response.userData },
            7
          );
          setAuthData({ token: response.token, userData: response.userData });
          toast.success("Sign in Sucessfully");
          if (response.userData.account_type === "Admin")
            navigate("/dashboard");
          else navigate("/");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const _this = {
    loginSubmit,
  };
  return <>{loading ? <Loader /> : <Body {..._this} />}</>;
};

export default Login;
