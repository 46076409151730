import { apiConnector, handleResponse } from "./core";
import { orderEndpoints } from "./apis";

const {
  CREATE_ORDER_API,
  GET_USER_ORDERS_API,
  GET_ADMIN_ORDERS_API,
  UPDATE_ORDER_STATUS_API,
  GET_ORDERS_ID_API,
  ADMIN_ORDER_CANCEL,
  CREATE_ORDER_ONLINE_PAYMENT_API,
  CHANGE_AMOUNT_PAID,
} = orderEndpoints;

const order = {
  CreateOrder: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CREATE_ORDER_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  CreteOrderOnlinePayment: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "POST",
        CREATE_ORDER_ONLINE_PAYMENT_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  GetOrders: async (token) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_USER_ORDERS_API, null, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  GetAllOrders: async (token) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ADMIN_ORDERS_API, null, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  GetOrderById: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        `${GET_ORDERS_ID_API}/${data.order_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  ChangeOrderStatus: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_ORDER_STATUS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  AdminOrderCancel: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", ADMIN_ORDER_CANCEL, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  ChangeAmountPaid: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", CHANGE_AMOUNT_PAID, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default order;
