import React from "react";
import { Navigate } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";

const AdminRoutes = ({ children }) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));

  if (authData !== null && authData?.userData?.account_type === "Admin") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoutes;
