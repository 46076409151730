import React, { useState, useEffect } from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import useAuthDataStore from "../../app/authStore";
import API from "../../api";
import toast from "react-hot-toast";
import Header from "./Header";
import Loader from "../../common/Loader";
import io from "socket.io-client";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io.connect(BASE_URL);

const Product = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const getAllProduct = () => {
    setLoading(true);
    API.product
      .GetAllProducts()
      .then((response) => {
        if (response) {
          setProductData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ChangeStatus = async (product, checked) => {
    if (product.product_quantity === 0)
      return toast.error("Product has no stock");
    const data = {
      product_id: product._id,
    };
    API.product
      .ChangeAvailable(authData?.token, data)
      .then((response) => {
        if (response) {
          setProductData((prevProduct) =>
            prevProduct.map((product) =>
              product._id === response._id
                ? { ...product, ...response }
                : product
            )
          );
          socket.emit("send_update_product_availability", response);
        }
      })
      .finally(() => {});
  };

  const deleteProduct = (product) => {
    setLoading(true);
    API.product
      .DeleteProduct(authData?.token, { product_id: product?._id })
      .then((response) => {
        if (response) {
          removeProductById(product?._id);
          toast.success("Product Deleted");
        }
      })
      .finally(() => {
        setLoading(false);
        setDeleteModal(false);
      });
  };

  function removeProductById(id) {
    const array = productData;
    const index = array.findIndex((obj) => obj._id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
    setProductData(array);
  }

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      if (query === "") {
        setFilteredItems(productData);
      } else {
        const queryWords = query.toLowerCase().split(" ").filter(Boolean);
        const filtered = productData.filter((item) =>
          queryWords.every((word) =>
            Object.values(item).some((value) =>
              String(value).toLowerCase().includes(word)
            )
          )
        );
        setFilteredItems(filtered);
      }
    };

    handleSearch();
  }, [query, productData]);

  const _this = {
    loading,
    setLoading,
    productData,
    setProductData,
    ChangeStatus,
    deleteModal,
    setDeleteModal,
    deleteProduct,
    query,
    setQuery,
    filteredItems,
    setFilteredItems,
  };
  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default Product;
