import React from "react";
import { useNavigate } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";

const CartPopup = (_this) => {
  const navigate = useNavigate();
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  return (
    <div className="fixed bottom-0 w-full lg:min-w-[1000px] lg:max-w-[1000px] z-50 shadow-custom">
      <div className="py-[20px] px-[16px] bg-white h-full rounded-t-3xl">
        {authData !== null ? (
          <div className="flex flex-col justify-start gap-y-5">
            <div className="flex-1 overflow-hidden gap-1">
              <div className="text-[18px] leading-5 tracking-[-0.3px] font-gilroy-semibold">
                {authData?.userData?.name}
              </div>
              <div className="text-[13px] leading-4 tracking-[-0.3px] font-gilroy-medium text-[#02060c99] mt-[4px]">
                {authData?.userData?.phone_number},{"  "}
                {authData?.userData?.room_number}-
                {authData?.userData?.floor_number},{"  "}
                {authData?.userData?.hostel_name}
              </div>
            </div>
            <button
              onClick={() => _this?.handlePlaceOrder()}
              className="p-[14px] h-auto bg-[#1ba672] text-white font-gilroy-bold text-[16px] leading-5 tracking-[-0.3px] break-words overflow-hidden rounded-[12px] transform hover:scale-95 transition-all duration-100 ease-in"
            >
              Process to Pay
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-y-5">
            <div className="flex-1 overflow-hidden gap-1">
              <div className="text-[18px] leading-5 tracking-[-0.3px] font-gilroy-semibold">
                Almost There
              </div>
              <div className="text-[13px] leading-4 tracking-[-0.3px] font-gilroy-medium text-[#02060c99] mt-[4px]">
                Login or Signup to place your order
              </div>
            </div>
            <button
              onClick={() => navigate("/sign-in")}
              className="p-[14px] h-auto bg-primary/90 text-white font-gilroy-bold text-[16px] leading-5 tracking-[-0.3px] break-words overflow-hidden rounded-[12px] transform hover:scale-95 transition-all duration-100 ease-in"
            >
              Process to Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPopup;
