import React from "react";
import Header from "./Header";
import SubCategoryTable from "./SubCategoryTable";
import DeleteModal from "./DeleteModal";

const Body = (_this) => {
  return (
    <>
      <Header />
      <SubCategoryTable {..._this} />
      <DeleteModal {..._this} />
    </>
  );
};

export default Body;
