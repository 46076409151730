import React, { useEffect, useState } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import { useParams } from "react-router-dom";
import API from "../../api";
import useCategoryStore from "../../app/categoryStore";

const EditSubCategory = () => {
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryDetails, setSubCategoryDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const { subcategory_id } = useParams();

  const getSubCategory = () => {
    setLoading(true);
    API.subCategory
      .GetSubCategory({ subcategory_id })
      .then((response) => {
        if (response) {
          setSubCategoryDetails(response);
        }
      })
      .finally(() => setLoading(false));
  };

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
    }
  }, []);

  useEffect(() => {
    getSubCategory();
  }, []);

  const _this = {
    subCategoryDetails,
    setSubCategoryDetails,
    categoryData,
    setCategoryData,
  };
  return (
    <DefaultLayout>
      <Body {..._this} />
    </DefaultLayout>
  );
};

export default EditSubCategory;
