import React, { useState, useEffect } from "react";
import Body from "./Body";
import Loader from "../../common/Loader";
import useAuthDataStore from "../../app/authStore";
import useCartDataStore from "../../app/cartStore";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import OrderPlaced from "./OrderPlaced";
import { useLocation } from "react-router-dom";

const Cart = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [count, setCount] = useState(5);
  const [paymentMod, setPaymentMod] = useState("Online");
  const navigate = useNavigate();
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { cartData, totalAmount, totalItem, discountAmount, clearCart } =
    useCartDataStore((state) => ({
      cartData: state.cartData,
      totalAmount: state.totalAmount,
      totalItem: state.totalItem,
      discountAmount: state.discountAmount,
      clearCart: state.clearCart,
    }));

  const transformCartData = (cartData) => {
    return cartData.map((item) => ({
      product: item.product._id,
      quantity: item.quantity,
    }));
  };
  const handlePlaceOrder = () => {
    const data = {
      user_id: authData?.userData?._id,
      order_items: transformCartData(cartData),
      total_items: totalItem,
      total_amount: totalAmount,
      discounted_amount: discountAmount,
      payment_mod: paymentMod,
      payment_id: null,
    };
    if (paymentMod === "COD") {
      setLoading(true);
      API.order
        .CreateOrder(authData.token, data)
        .then((response) => {
          if (response) {
            clearCart();
            setOrderPlaced(true);
            toast.success("Order Placed");
          }
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setOrderPlaced(false);
            navigate("/");
          }, "5000");
        });
    } else {
      data.name = "Kolkata Mart";
      data.amount = discountAmount;
      data.number = "8016223319";
      data.MUID = "MUID" + Date.now();
      data.transactionId = "T" + Date.now();
      data.token = authData.token;
      setLoading(true);
      API.order
        .CreteOrderOnlinePayment(authData.token, { ...data })
        .then((response) => {
          if (response) {
            window.location.href = response;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getURLParameter = (name) => {
    return new URLSearchParams(window.location.search).get(name);
  };

  // Function to handle payment status
  const handlePaymentStatus = () => {
    const paymentStatus = getURLParameter("status");
    console.log(paymentStatus);

    if (paymentStatus === "success") {
      // Payment was successful, clear cart from local storage
      // localStorage.removeItem("cart");
      alert("Payment successful! Your cart has been cleared.");

      // Redirect to another page after clearing the cart (optional)
      navigate("/");
    } else if (paymentStatus === "failure") {
      // Handle payment failure if needed
      alert("Payment failed. Please try again.");
    }
  };

  useEffect(() => {
    handlePaymentStatus();
  }, []);

  useEffect(() => {
    let timer;
    if (orderPlaced && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    // Clear the interval when the component unmounts or count reaches 0
    return () => clearInterval(timer);
  }, [orderPlaced, count]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");

    if (status === "success") {
      clearCart();
      setOrderPlaced(true);
      setTimeout(() => {
        setOrderPlaced(false);
        navigate("/");
      }, "5000");
    }
  }, []);

  const _this = {
    handlePlaceOrder,
    count,
    paymentMod,
    setPaymentMod,
  };
  return loading ? (
    <Loader />
  ) : orderPlaced ? (
    <OrderPlaced {..._this} />
  ) : (
    <Body {..._this} />
  );
};

export default Cart;
