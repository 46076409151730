import create from "zustand";

const dashboardLoading = (set) => ({
  loading: false,
  setLoading: (data) => {
    set((state) => ({
      loading: data,
    }));
  },
});

const useDashboardLoading = create(dashboardLoading);

export default useDashboardLoading;
