import React from "react";
import { useNavigate } from "react-router-dom";
import useCartDataStore from "../../app/cartStore";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const CartPopup = ({ isVisible }) => {
  const navigate = useNavigate();
  const { cartData, discountAmount } = useCartDataStore((state) => ({
    cartData: state.cartData,
    discountAmount: state.discountAmount,
  }));

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 z-50 transition-transform ${
        isVisible ? "ModalOpen" : "ModalClosed"
      }`}
    >
      <div
        className="max-w-[1000px] bg-white mx-auto rounded-t-2xl"
        style={{ boxShadow: "0 -4px 18px rgba(0, 0, 0, .2)" }}
      >
        <div className="h-[70px] px-7">
          <div className="flex justify-between items-center h-full">
            <div className="text-[#02060cbf] font-gilroy-bold leading-4 text-[14px] -tracking-[0.1] flex items-center gap-x-2">
              <div className="flex">
                {cartData.length > 1 && (
                  <div className="flex">
                    {cartData.length > 2 && (
                      <div aria-hidden="true" className="_3z4E2"></div>
                    )}
                    <div
                      aria-hidden="true"
                      className={`${
                        cartData.length > 2 && "-ml-[30px]"
                      } _3z4E2`}
                    ></div>
                  </div>
                )}
                <div
                  className={`h-[38px] w-[38px] rounded-[8px] border border-black/40 ${
                    cartData.length > 1 && "-ml-[30px]"
                  } `}
                >
                  <img
                    src={`${BASE_URL}${cartData[0]?.product?.product_image[0]}`}
                    className="h-full w-full object-fill rounded-[8px]"
                  />
                </div>
              </div>
              <span>{cartData.length} items</span>
              <span className="rs">{discountAmount}</span>
            </div>
            <button
              onClick={() => navigate("/cart")}
              className="h-[38px] py-[9px] px-[13px] bg-[#1ba672] rounded-[12px] font-gilroy-bold text-white leading-5 text-[16px] -tracking-[0.3]"
            >
              Go to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
