import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import useDashboardLoading from "../../app/dashboardLoading";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Body = (_this) => {
  let { category_id } = useParams();
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { setLoading } = useDashboardLoading((state) => ({
    setLoading: state.setLoading,
  }));
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const fileProps = {
    action: "",
    name: "file",
    multiple: true,
    customRequest(e) {
      e.onSuccess();
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        category_name: "",
        category_description: "",
      });
    }
  }, [reset, isSubmitSuccessful]);

  /*==================================
            Create Category
    =====================================
    */
  const onCreateCategory = (formValue) => {
    let formData = new FormData();
    formData.append("category_id", category_id);
    formData.append("category_name", formValue.category_name);
    formData.append("category_description", formValue.categoy_description);
    if (fileList.length > 0) {
      formData.append("category_image_file", fileList[0]?.originFileObj);
    }

    setLoading(true);
    API.category
      .UpdateCategory(authData.token, formData)
      .then((response) => {
        if (response) {
          toast.success("Category Updated successfully");
          navigate(-1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="p-4 md:p-6 2xl:p-10">
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(onCreateCategory)}
        >
          {/* <!-- Input Fields --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Edit Category Information
              </h3>
              <p className="text-sm text-strokedark/60">
                Easily input essential details like name, and more to showcase
                your category.
              </p>
            </div>

            <div>
              <div className="flex flex-col gap-5 p-6">
                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Category Name <span className="text-meta-1">*</span>
                    {errors.category_name && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Category name is required.
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    defaultValue={_this?.state?.category_name}
                    placeholder="Category Name"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("category_name", { required: true })}
                  />
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Category Description <span className="text-meta-1">*</span>
                    {errors.categoy_description && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Description is required.
                      </span>
                    )}
                  </label>
                  <textarea
                    rows={6}
                    defaultValue={_this?.state?.category_description}
                    placeholder="Type your category description"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("categoy_description", { required: true })}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Edit Category Images <span className="text-meta-1">*</span>
              </h3>
              <p className="text-sm text-strokedark/60">
                Upload captivating images and videos to make your category stand
                out.
              </p>
            </div>

            <div>
              <div className="flex flex-col gap-5 p-6">
                <Upload
                  action="#"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  {...fileProps}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button className="flex justify-center text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90">
              Edit Category
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Body;
