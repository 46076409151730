import create from "zustand";

const calculateTotals = (cartData) => {
  const totalItem = cartData.reduce((acc, item) => acc + item.quantity, 0);
  const totalAmount = cartData.reduce(
    (acc, item) => acc + item.product?.product_price * item.quantity,
    0
  );
  const discountAmount = cartData.reduce(
    (acc, item) => acc + item.product?.discounted_price * item.quantity,
    0
  );
  return { totalItem, totalAmount, discountAmount };
};

const cartStore = (set) => {
  const storedData = localStorage.getItem("km_cart_data")
    ? JSON.parse(localStorage.getItem("km_cart_data"))
    : { cartData: [], totalItem: 0, totalAmount: 0 };

  return {
    cartData: storedData.cartData,
    totalItem: storedData.totalItem,
    totalAmount: storedData.totalAmount,
    discountAmount: storedData.discountAmount,
    addToCart: (product) =>
      set((state) => {
        const existingProductIndex = state?.cartData?.findIndex(
          (item) => item.product._id === product._id
        );

        let updatedCartData;
        if (existingProductIndex !== -1) {
          updatedCartData = state?.cartData?.map((item, index) =>
            index === existingProductIndex
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          updatedCartData = [...state.cartData, { product, quantity: 1 }];
        }
        const { totalItem, totalAmount, discountAmount } =
          calculateTotals(updatedCartData);
        const updatedState = {
          cartData: updatedCartData,
          totalItem,
          totalAmount,
          discountAmount,
        };
        localStorage.setItem("km_cart_data", JSON.stringify(updatedState));
        return updatedState;
      }),
    increaseQuantity: (productId) =>
      set((state) => {
        const updatedCartData = state.cartData.map((item) =>
          item.product._id === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        const { totalItem, totalAmount, discountAmount } =
          calculateTotals(updatedCartData);
        const updatedState = {
          cartData: updatedCartData,
          totalItem,
          totalAmount,
          discountAmount,
        };
        localStorage.setItem("km_cart_data", JSON.stringify(updatedState));
        return updatedState;
      }),
    decreaseQuantity: (productId) =>
      set((state) => {
        const updatedCartData = state.cartData
          .map((item) =>
            item.product._id === productId
              ? { ...item, quantity: item.quantity - 1 }
              : item
          )
          .filter((item) => item.quantity > 0);
        const { totalItem, totalAmount, discountAmount } =
          calculateTotals(updatedCartData);
        const updatedState = {
          cartData: updatedCartData,
          totalItem,
          totalAmount,
          discountAmount,
        };
        localStorage.setItem("km_cart_data", JSON.stringify(updatedState));
        return updatedState;
      }),

    clearCart: () => {
      localStorage.removeItem("km_cart_data");
      set({ cartData: [], totalItem: 0, totalAmount: 0, discountAmount: 0 });
    },
  };
};

const useCartDataStore = create(cartStore);

export default useCartDataStore;
