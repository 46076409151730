import React from "react";
import { Table, Switch, ConfigProvider } from "antd";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: "left",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    align: "center",
  },
  {
    title: "Registration Number",
    dataIndex: "registration_number",
    align: "center",
  },
  {
    title: "Room / Floor",
    dataIndex: "room_floor",
    align: "center",
  },
  {
    title: "Hostel Name",
    dataIndex: "hostel_name",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "order_count",
    align: "center",
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   align: "center",
  // },
];

const CustomersTable = (_this) => {
  const createObject = (customer, index) => {
    return {
      key: index,
      _id: customer?._id,
      name: (
        <p className=" text-dashboardPrimary hover:underline hover:text-dashboardPrimary font-satoshi cursor-pointer">
          {customer?.name}
        </p>
      ),
      email: <p className="font-satoshi">{customer?.email}</p>,
      phone_number: <p className="font-satoshi">{customer?.phone_number}</p>,
      registration_number: (
        <div className="flex  gap-x-3 justify-center font-satoshi">
          {customer?.registration_number}
        </div>
      ),
      room_floor: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          {customer?.room_number} / {customer?.floor_number}
        </div>
      ),
      hostel_name: (
        <div className="flex justify-center font-satoshi">
          {customer?.hostel_name}
        </div>
      ),
      order_count: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          {customer?.order_count}
        </div>
      ),
    };
  };
  const data = _this?.customersData?.map((customer, index) =>
    createObject(customer, index)
  );
  return (
    <div className="p-4 md:p-6 2xl:p-10">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default CustomersTable;
