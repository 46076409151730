import React from "react";
import ProductListNavbar from "./ProductListNavbar";
import CategorySidebar from "./CategorySidebar";
import ProductList from "./ProductList";
import CartPopup from "../../common/CartPopup";

const Body = (_this) => {
  return (
    <div className="flex flex-col items-center overflow-y-hidden no-scrollbar">
      <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] bg-[#f0f0f5] overflow-y-hidden no-scrollbar">
        <div className="no-scrollbar overflow-y-hidden">
          <ProductListNavbar {..._this} />
          <div className="mt-[4px] w-full flex gap-x-2 h-full no-scrollbar">
            <CategorySidebar {..._this} />
            <ProductList {..._this} />
          </div>
          {_this?.cartData && _this?.cartData.length > 0 && (
            <CartPopup isVisible={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
