import React, { useState, useEffect } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import API from "../../api";
import useCategoryStore from "../../app/categoryStore";

const AddSubCategory = () => {
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
    }
  }, []);

  const _this = {
    categoryData,
    setCategoryData,
  };
  return (
    <DefaultLayout>
      <Body {..._this} />
    </DefaultLayout>
  );
};

export default AddSubCategory;
