const Loader = ({ dashboard }) => {
  return (
    <div
      className={`flex ${
        dashboard ? "h-[calc(100vh-144px)]" : "h-screen"
      } items-center justify-center bg-white`}
    >
      <div
        className={`h-16 w-16 animate-spin rounded-full border-4 border-solid ${
          dashboard ? "border-dashboardPrimary" : "border-primary"
        }  border-t-transparent`}
      ></div>
    </div>
  );
};

export default Loader;
