import React from "react";
import { Link } from "react-router-dom";
import { Empty } from "antd";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
import Navbar from "./Navbar";
import EmptyOrder from "./EmptyOrder";
import OrdersList from "./OrdersList";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Body = (_this) => {
  return (
    <>
      <div className="flex flex-col items-center relative">
        <div
          className={`w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] ${
            _this.ordersData && _this.ordersData.length === 0
              ? "bg-white "
              : "bg-cartBg/60 "
          } relative`}
        >
          <Navbar />
          {_this.ordersData && _this.ordersData.length === 0 ? (
            <EmptyOrder />
          ) : (
            <div className="mx-6 my-6">
              <OrdersList {..._this} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Body;
