import React from "react";

const Body = () => {
  return (
    <div className="flex gap-y-2 min-h-[calc(100vh-100px)] w-[100%] justify-center overflow-x-hidden bg-black/10">
      <div className="h-full w-11/12 max-w-maxContent mx-auto bg-white mt-6 mb-6 py-4 px-6">
        <div className="flex flex-col items-center gap-y-3 ">
          <h1 className="text-xl font-bold flex flex-col items-center">
            PRIVACY POLICY
            <span className="text-xs font-light">
              Last updated May 07, 2024
            </span>
          </h1>

          <div className="text-sm">
            <p>
              This privacy notice for Kolkata Mart ('we', 'us', or 'our'),
              describes how and why we might collect, store, use, and/or share
              ('process') your information when you use our services
              ('Services'), such as when you:
            </p>
            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://thekolkatamart.com/">
                  https://thekolkatamart.com
                </a>
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              Questions or concerns? Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services.
              If you still have any questions or concerns, please contact us at{" "}
              <a
                // href="mailto:caledonkababs@gmail.com"
                className="text-primary"
              >
                kolkatamart@gmail.com
              </a>
              .
            </p>
          </div>
          <div className="text-sm">
            <h1 className="text-lg font-bold">Summary of Key Points</h1>
            <h2>
              In what situations and with which parties do we share personal
              information?
            </h2>
            <p>
              We may share information in specific situations and with specific
              third parties.{" "}
              <a href="#learn-more-share-information">
                Learn more about when and with whom we share your personal
                information.
              </a>
            </p>

            <h2>How do we keep your information safe?</h2>
            <p>
              We have organisational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.{" "}
              <a href="#learn-more-keep-information-safe">
                Learn more about how we keep your information safe.
              </a>
            </p>

            <h2>What are your rights?</h2>
            <p>
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information.{" "}
              <a href="#learn-more-privacy-rights">
                Learn more about your privacy rights.
              </a>
            </p>

            <h2>How do you exercise your rights?</h2>
            <p>
              The easiest way to exercise your rights is by submitting a data
              subject access request, or by contacting us. We will consider and
              act upon any request in accordance with applicable data protection
              laws.
            </p>

            <p>
              Want to learn more about what we do with any information we
              collect?{" "}
              <a href="#full-privacy-notice">
                Review the privacy notice in full.
              </a>
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              1. WHAT INFORMATION DO WE COLLECT?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We collect personal information that
              you provide to us.
            </p>

            <p className="text-sm">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>

            <p className="text-sm">
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </p>

            <ul className="text-sm list-disc pl-5">
              <li>Names</li>
              <li>Phone numbers</li>
              <li>Email addresses</li>
              <li>Mailing addresses</li>
              <li>Passwords</li>
            </ul>

            <p className="text-sm">
              <strong>Sensitive Information:</strong> We do not process
              sensitive information.
            </p>

            <p className="text-sm">
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you make purchases, such as your payment
              instrument number, and the security code associated with your
              payment instrument. All payment data is stored by __________. You
              may find their privacy notice link(s) here: __________.
            </p>

            <p className="text-sm">
              <strong>Social Media Login Data.</strong> We may provide you with
              the option to register with us using your existing social media
              account details, like your Facebook, Twitter, or other social
              media account. If you choose to register in this way, we will
              collect the information described in the section called 'HOW DO WE
              HANDLE YOUR SOCIAL LOGINS?' below.
            </p>

            <p className="text-sm">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We process your information to provide,
              improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process your information for other purposes with your consent.
            </p>

            <p className="text-sm">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>

            <ul className="text-sm list-disc pl-5">
              <li>
                To facilitate account creation and authentication and otherwise
                manage user accounts. We may process your information so you can
                create and log in to your account, as well as keep your account
                in working order.
              </li>
              {/* Add more list items as needed */}
            </ul>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We may share information in specific
              situations described in this section and/or with the following
              third parties.
            </p>

            <p className="text-sm">
              We may need to share your personal information in the following
              situations:
            </p>

            <ul className="text-sm list-disc pl-5">
              <li>
                <strong>Business Transfers:</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
              <li>
                <strong>When we use Google Analytics:</strong> We may share your
                information with Google Analytics to track and analyze the use
                of the Services. To opt out of being tracked by Google Analytics
                across the Services, visit{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . For more information on the privacy practices of Google,
                please visit the{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Privacy & Terms
                </a>{" "}
                page.
              </li>
            </ul>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> If you choose to register or log in to
              our Services using a social media account, we may have access to
              certain information about you.
            </p>

            <p className="text-sm">
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>

            <p className="text-sm">
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law.
            </p>

            <p className="text-sm">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us to keep your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>

            <p className="text-sm">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              6. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We aim to protect your personal
              information through a system of organizational and technical
              security measures.
            </p>

            <p className="text-sm">
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
            </p>

            <p className="text-sm">
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              7. DO WE COLLECT INFORMATION FROM MINORS?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age.
            </p>

            <p className="text-sm">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at __________.
            </p>
          </div>

          <div>
            <h1 className="text-lg font-bold">
              8. WHAT ARE YOUR PRIVACY RIGHTS?
            </h1>

            <p className="text-sm">
              <strong>In Short:</strong> You may review, change, or terminate
              your account at any time.
            </p>

            <p className="text-sm">
              <strong>Withdrawing your consent:</strong> If we are relying on
              your consent to process your personal information, which may be
              express and/or implied consent depending on the applicable law,
              you have the right to withdraw your consent at any time. You can
              withdraw your consent at any time by contacting us using the
              contact details provided in the section 'HOW CAN YOU CONTACT US
              ABOUT THIS NOTICE?' below.
            </p>

            <p className="text-sm">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
          </div>
          <div>
            <h1 className="text-lg font-bold">Account Information</h1>

            <p className="text-sm">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>

            <ul className="text-sm list-disc pl-5">
              <li>
                Log in to your account settings and update your user account.
              </li>
              <li>Contact us using the contact information provided.</li>
            </ul>

            <p className="text-sm">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>

            <p className="text-sm">
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <a href="mailto:caledonkababs@gmail.com">
                caledonkababs@gmail.com
              </a>
              .
            </p>
          </div>
          <div>
            <h1 className="text-lg font-bold">
              9. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h1>
            <p className="text-sm">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ('DNT') feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage, no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h1 className="text-lg font-bold">
              10. DO WE MAKE UPDATES TO THIS NOTICE?
            </h1>
            <p className="text-sm">
              <strong>In Short:</strong> Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </p>
            <p className="text-sm">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated 'Revised' date, and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h1 className="text-lg font-bold">
              11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h1>
            <p className="text-sm">
              If you have questions or comments about this notice, you may
              contact us by post at:
            </p>
            <p className="text-sm">
              Kolkata Mart
              <br />
              New Town
              <br />
              Kolkata, West Bengal 700135
              <br />
              India
            </p>
            <h1 className="text-lg font-bold">
              12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h1>
            <p className="text-sm">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please fill
              out and submit a data subject access request.
            </p>
            <br></br>
            <p className="text-sm ">
              Kolkata mart belongs to{" "}
              <span className="text-primary">
                HUMMINGBIRD VENTURES PRIVATE LIMITED
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
