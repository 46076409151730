import React from "react";
import { Table, Switch, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const columns = [
  {
    title: "Sub Category",
    dataIndex: "sub_category_name",
    fixed: "left",
    width: 300,
  },
  {
    title: "Category",
    dataIndex: "category",
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "center",
  },
];

const SubCategoryTable = (_this) => {
  const createObject = (subCategory, index) => {
    return {
      key: index,
      _id: subCategory?._id,
      sub_category_name: (
        <div className="flex items-center gap-x-3">
          <div className="h-[48px] w-[48px]">
            <img
              src={`${BASE_URL}${subCategory?.subcategory_Image}`}
              alt="productImage"
              className="w-full h-full"
            />
          </div>
          <div>
            <p className="text-md font-satoshi">
              {subCategory?.subcategory_name}
            </p>
          </div>
        </div>
      ),
      category: (
        <p className="font-satoshi">
          {subCategory?.product_category?.category_name}
        </p>
      ),
      action: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          <Link
            to={`/dashboard/manage-sub-categories/edit/${subCategory?._id}`}
            state={subCategory}
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
            </svg>
          </Link>
          <button onClick={() => _this?.setDeleteModal(subCategory?._id)}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"></path>
            </svg>
          </button>
        </div>
      ),
    };
  };
  const data = _this?.subCategoryData.map((subCategory, index) =>
    createObject(subCategory, index)
  );
  return (
    <div className="p-4 md:p-6 2xl:p-10 font-satoshi">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default SubCategoryTable;
