import React from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import { useLocation } from "react-router-dom";
import Loader from "../../common/Loader";
import useDashboardLoading from "../../app/dashboardLoading";

const EditCategory = () => {
  const { state } = useLocation();
  const { loading } = useDashboardLoading((state) => ({
    loading: state.loading,
  }));

  const _this = {
    state,
  };
  return (
    <>
      <DefaultLayout>
        {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
      </DefaultLayout>
    </>
  );
};

export default EditCategory;
