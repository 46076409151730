import { apiConnector, handleResponse } from "./core";
import { subCategoryEndPoints } from "./apis";

const {
  GET_SUBCATEGORY_BY_CATEGORY,
  CREATE_SUBCATEGORY_API,
  DELETE_SUBCATEGORY_API,
  GET_SUBCATEGORY_API,
  UPDATE_SUBCATEGORY_API,
} = subCategoryEndPoints;

const subCategory = {
  GetSubCategoryByCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        `${GET_SUBCATEGORY_BY_CATEGORY}/${data.category_id}`,
        data
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetSubCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        `${GET_SUBCATEGORY_API}/${data.subcategory_id}`,
        data
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  CreateSubCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CREATE_SUBCATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateSubCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_SUBCATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  DeleteSubCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "DELETE",
        `${DELETE_SUBCATEGORY_API}/${data?._id}`,
        data,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  //   UpdateAvailability: async (token, data) => {
  //     let response = null;
  //     try {
  //       response = await apiConnector(
  //         "PUT",
  //         `${UPDATE_PRODUCT_QUANTITY_API}/${data?._id}`,
  //         data,
  //         {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${token}`,
  //         }
  //       );
  //     } catch (error) {
  //       response = error;
  //     }
  //     return handleResponse(response);
  //   },
};

export default subCategory;
