import React from "react";
import Body from "./Body";
import { useLocation } from "react-router-dom";

const CustomerOrderDetails = () => {
  const { state } = useLocation();

  const _this = {
    order: state,
  };
  return <Body {..._this} />;
};

export default CustomerOrderDetails;
