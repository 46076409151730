import React from "react";
import Header from "./Header";
import OrderTable from "./OrderTable";

const Body = (_this) => {
  return (
    <>
      <OrderTable {..._this} />
    </>
  );
};

export default Body;
