import { apiConnector, handleResponse } from "./core";
import { categoryEndpoints } from "./apis";

const {
  GET_ALL_CATEGORY_API,
  GET_ALL_CATEGORY_PRODUCT_SUBCATEGORY_API,
  GET_CATEGORY_API,
  CREATE_CATEGORY_API,
  UPDATE_CATEGORY_API,
  DELETE_CATEGORY_API,
  UPDATE_PRODUCT_QUANTITY_API,
} = categoryEndpoints;

const category = {
  GetAllCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        GET_ALL_CATEGORY_PRODUCT_SUBCATEGORY_API,
        data
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetCategories: async (data) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ALL_CATEGORY_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  CreateCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CREATE_CATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_CATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  DeleteCategory: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "DELETE",
        `${DELETE_CATEGORY_API}/${data?._id}`,
        data,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateAvailability: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "PUT",
        `${UPDATE_PRODUCT_QUANTITY_API}/${data?._id}`,
        data,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default category;
