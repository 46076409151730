import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProductListNavbar = (_this) => {
  const navigate = useNavigate();
  return (
    <div
      className="z-10 bg-[#fff] overflow-hidden px-1 w-full"
      style={{ boxShadow: "0 2px 3px -2px #e3dbe0" }}
    >
      <div className="h-[64px] bg-[#fff] flex items-center justify-between">
        <button className="w-[5%]">
          <div className="flex justify-center" onClick={() => navigate("/")}>
            <IoArrowBack />
          </div>
        </button>
        <div className="mx-[10px] flex justify-start w-[84%]">
          <div className="select-none flex">
            <div className="h-[38px] w-[38px] border border-[#02060c0d] rounded-[8px] bg-[#fff]">
              <img
                src={`${BASE_URL}${_this?.category?.category_image}`}
                alt="img"
                className="object-fill bg-transparent h-full w-full"
              />
            </div>
            <div className="flex flex-col items-start ml-[8px]">
              <h5 className="productListNav_heading font-gilroy-bold">
                {_this?.category?.category_name}
              </h5>
              <p className="productListNav_subheading font-gilroy-medium">
                {_this?.category && _this?.category?.products?.length} items
              </p>
            </div>
          </div>
        </div>
        <Link to="/search">
          <div className="w-[11%] flex items-center">
            <div className="h-[36px] w-[36px] flex justify-center items-center">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                aria-hidden="true"
                strokecolor="rgba(2, 6, 12, 0.92)"
                fillcolor="rgba(2, 6, 12, 0.75)"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.0998 8.84232C13.0998 11.7418 10.7493 14.0922 7.84989 14.0922C4.95046 14.0922 2.6 11.7418 2.6 8.84232C2.6 5.94289 4.95046 3.59243 7.84989 3.59243C10.7493 3.59243 13.0998 5.94289 13.0998 8.84232ZM12.1431 14.1802C10.9686 15.1261 9.47534 15.6922 7.84989 15.6922C4.0668 15.6922 1 12.6254 1 8.84232C1 5.05923 4.0668 1.99243 7.84989 1.99243C11.633 1.99243 14.6998 5.05923 14.6998 8.84232C14.6998 10.4974 14.1128 12.0153 13.1357 13.1993L18.319 17.9606C18.7226 18.3313 18.7359 18.9637 18.3483 19.3511C17.9634 19.7357 17.3365 19.7254 16.9645 19.3282L12.1431 14.1802Z"
                  fill="rgba(2, 6, 12, 0.75)"
                  fillOpacity="0.92"
                ></path>
              </svg>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductListNavbar;
