const BASE_URL = process.env.REACT_APP_BASE_URL;

// AUTH ENDPOINTS
export const authEndpoints = {
  SEND_OTP_API: BASE_URL + "/api/v1/auth/sendotp",
  SIGNUP_API: BASE_URL + "/api/v1/auth/signup",
  LOGIN_API: BASE_URL + "/api/v1/auth/login",
  FORGOT_PASSWORD_API: BASE_URL + "/api/v1/auth/forgot-password",
  RESEND_OTP_API: BASE_URL + "/api/v1/auth/resend-otp",
  UPDATE_ADDRESS: BASE_URL + "/api/v1/auth/update-address",
  UPDATE_EMAIL: BASE_URL + "/api/v1/auth/update-email",
  UPDATE_CUSTOMER_DETAILS: BASE_URL + "/api/v1/auth/update-customer-details",
  CHANGE_PASSWORD: BASE_URL + "/api/v1/auth/change-password",
  UPDATE_USER_DETAILS: BASE_URL + "/api/v1/auth/update-user-details",
  GET_HOSTELS_API: BASE_URL + "/api/v1/auth/get-hostels",
};

// CATEGORY ENDPOINTS
export const categoryEndpoints = {
  GET_ALL_CATEGORY_API: BASE_URL + "/api/v1/category/get-all-category",
  GET_ALL_CATEGORY_PRODUCT_SUBCATEGORY_API:
    BASE_URL + "/api/v1/category/get-all-category-with-product-and-subcategory",
  GET_CATEGORY_API: BASE_URL + "/api/v1/category/get-all-category/:category_id",
  CREATE_CATEGORY_API: BASE_URL + "/api/v1/category/create-category",
  UPDATE_CATEGORY_API: BASE_URL + "/api/v1/category/update-category",
  DELETE_CATEGORY_API: BASE_URL + "/api/v1/category/delete-category",
  UPDATE_PRODUCT_QUANTITY_API: BASE_URL + "/api/v1/category/modify-isavailable",
};

export const subCategoryEndPoints = {
  GET_SUBCATEGORY_BY_CATEGORY:
    BASE_URL + "/api/v1/subcategory/get-subcategory-by-categoryid",
  GET_SUBCATEGORY_API: BASE_URL + "/api/v1/subcategory/get-subcategory",
  CREATE_SUBCATEGORY_API: BASE_URL + "/api/v1/subcategory/create-subcategory",
  DELETE_SUBCATEGORY_API: BASE_URL + "/api/v1/subcategory/delete-subcategory",
  UPDATE_SUBCATEGORY_API: BASE_URL + "/api/v1/subcategory/update-subcategory",
};

// PRODUCT ENDPOINTS
export const productEndpoints = {
  GET_ALL_PRODUCTS_API: BASE_URL + "/api/v1/products/get-all-products",
  GET_PRODUCTS_BY_CATEGORY_API:
    BASE_URL + "/api/v1/products/get-products-by-category",
  GET_ALL_PRODUCTS_BY_CATEGORY_API:
    BASE_URL + "/api/v1/products/get-all-products-by-category",
  CREATE_PRODUCT_API: BASE_URL + "/api/v1/products/create-product",
  DELETE_PRODUCT_API: BASE_URL + "/api/v1/products/delete-product",
  UPDATE_PRODUCT_API: BASE_URL + "/api/v1/products/update-product",
  UPDATE_PRODUCT_AVAILABILITY_API:
    BASE_URL + "/api/v1/products/update-product-availability",
  UPDATE_UNIVERSAL_AVAILABILITY_API:
    BASE_URL + "/api/v1/products/update-universal-availability",
  UPDATE_PRODUCT_QUANTITY_API:
    BASE_URL + "/api/v1/products/update-product-quantity",
};

// ORDERS ENDPOINTS
export const orderEndpoints = {
  CREATE_ORDER_API: BASE_URL + "/api/v1/order/create-order",
  CREATE_ORDER_ONLINE_PAYMENT_API: BASE_URL + "/api/v1/payment/capture-payment",
  UPDATE_ORDER_STATUS_API: BASE_URL + "/api/v1/order/update-order-status",
  GET_USER_ORDERS_API: BASE_URL + "/api/v1/order/get-orders",
  GET_ORDERS_ID_API: BASE_URL + "/api/v1/order/get-orders-by-id",
  GET_ADMIN_ORDERS_API: BASE_URL + "/api/v1/order/get-all-orders",
  GET_ORDERS_REQUEST_API: BASE_URL + "/api/v1/order/get-order-request",
  ORDER_ACCEPT_API: BASE_URL + "/api/v1/order/order-accept",
  GET_ANALYTICS_API: BASE_URL + "/api/v1/order/get-analytics",
  GET_CATEGORY_ANALYTICS_API: BASE_URL + "/api/v1/order/get-category-analytics",
  GET_ORDER_ANALYTICS_API: BASE_URL + "/api/v1/order/get-order-analytics",
  GET_TOP_ORDERING_CUSTOMERS_API:
    BASE_URL + "/api/v1/order/get-top-ordering-customers",
  ADMIN_ORDER_CANCEL: BASE_URL + "/api/v1/order/admin-order-cancel",
  CHANGE_AMOUNT_PAID: BASE_URL + "/api/v1/order/change-amount-paid",
};

// Analytics ENDPOINTS
export const analyticsEndpoints = {
  GET_ANALYTICS_API: BASE_URL + "/api/v1/analytics/get-analytics",
  GET_CATEGORY_ANALYTICS_API:
    BASE_URL + "/api/v1/analytics/get-category-analytics",
  GET_ORDER_ANALYTICS_API: BASE_URL + "/api/v1/analytics/get-order-analytics",
  GET_TOP_ORDERING_CUSTOMERS_API:
    BASE_URL + "/api/v1/analytics/get-top-ordering-customers",
  GET_PRODUCT_ORDER_COUNT_PER_MONTH:
    BASE_URL + "/api/v1/analytics/get-product-order-count-per-month",
  GET_TOP_SELLING_PRODUCT_API:
    BASE_URL + "/api/v1/analytics/get-top-selling-products",
};
