import toast from "react-hot-toast";
import { handleResponse } from "./core";
import { authEndpoints } from "./apis";
import { apiConnector } from "./core";
import { deleteCookie } from "../utils";

const {
  SEND_OTP_API,
  SIGNUP_API,
  LOGIN_API,
  FORGOT_PASSWORD_API,
  RESEND_OTP_API,
  UPDATE_ADDRESS,
  CHANGE_PASSWORD,
  UPDATE_USER_DETAILS,
  GET_HOSTELS_API,
  UPDATE_EMAIL,
  UPDATE_CUSTOMER_DETAILS,
} = authEndpoints;

const auth = {
  Login: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", LOGIN_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  SendOtp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", SEND_OTP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  SignUp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", SIGNUP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ForgotPassword: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", FORGOT_PASSWORD_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ResendOtp: async (data) => {
    let response = null;
    try {
      response = await apiConnector("POST", RESEND_OTP_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateAddress: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_ADDRESS, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateEmail: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_EMAIL, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateCustomerDetails: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_CUSTOMER_DETAILS, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ChangePassword: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CHANGE_PASSWORD, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateUserDetails: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_USER_DETAILS, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetHostels: async () => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_HOSTELS_API);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  Logout: async (navigate, clearAuthData) => {
    console.log("Hello");
    deleteCookie("authentications");
    clearAuthData();
    navigate("/");
    toast.success("Logged Out");
  },
};

export default auth;
