import React from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import useDashboardLoading from "../../app/dashboardLoading";
import Loader from "../../common/Loader";

const AddCategory = () => {
  const { loading } = useDashboardLoading((state) => ({
    loading: state.loading,
  }));

  return (
    <>
      <DefaultLayout>
        {loading ? <Loader dashboard={true} /> : <Body />}
      </DefaultLayout>
    </>
  );
};

export default AddCategory;
