import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = (_this) => {
  const navigate = useNavigate();
  return (
    <div className="z-10 bg-[#fff] overflow-hidden px-1 w-full rounded-b-3xl">
      <div className="h-[64px] bg-[#fff] flex items-center justify-start">
        <button className="">
          <div
            className="flex justify-center mb-1"
            onClick={() => navigate(-1)}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              aria-hidden="true"
              strokecolor="rgba(2, 6, 12, 0.92)"
              fillcolor="rgba(2, 6, 12, 0.6)"
            >
              <path
                d="M9.35613 3.45006C9.7599 3.07325 10.3927 3.09511 10.7695 3.49888C11.1463 3.90266 11.1245 4.53544 10.7207 4.91225L5.89328 9.41726C5.65564 9.63903 5.45297 9.82863 5.27991 9.99493L18.9938 9.99493C19.5461 9.99493 19.9938 10.4426 19.9938 10.9949C19.9938 11.5472 19.5461 11.9949 18.9938 11.9949L5.2162 11.9949C5.4027 12.1757 5.62584 12.3845 5.89326 12.6341L10.6626 17.085C11.0664 17.4618 11.0882 18.0946 10.7114 18.4984C10.3346 18.9021 9.7018 18.924 9.29803 18.5472L4.48094 14.0517C3.93464 13.5419 3.45138 13.091 3.1139 12.6762C2.74838 12.2269 2.44919 11.6972 2.44919 11.0257C2.44919 10.3541 2.74839 9.82444 3.11392 9.37513C3.4514 8.96029 3.93465 8.50938 4.48095 7.99965L9.35613 3.45006Z"
                fill="rgba(2, 6, 12, 0.6)"
                fillOpacity="0.92"
              ></path>
            </svg>
          </div>
        </button>
        <div className="mx-[10px] flex justify-start">
          <div className="select-none flex">
            <h5 className="productListNav_heading font-gilroy-semibold">
              Order #{_this?.order?.order_id}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
