import React from "react";
import Navbar from "./Navbar";
import { useForm } from "react-hook-form";
import useAuthDataStore from "../../app/authStore";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider } from "antd";

const Body = (_this) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });
  return (
    <>
      <div className="flex flex-col items-center relative">
        <div
          className={`w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] ${
            _this.ordersData && _this.ordersData.length === 0
              ? "bg-white "
              : "bg-cartBg/60 "
          } relative`}
        >
          <Navbar />

          <div className="h-full flex items-center my-7 px-4">
            {/* <!-- Sign In Form --> */}
            <div className=" bg-white w-full lg:w-10/12 mx-auto border">
              <form className="" onSubmit={handleSubmit(_this?.onSaveAddress)}>
                <div className="p-6">
                  <div className="w-full flex flex-col lg:flex-row justify-between gap-3 mb-4">
                    <div className="w-full">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Room Number <span className="text-meta-1">*</span>{" "}
                        {errors.room_number && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Room number is required.
                          </span>
                        )}
                      </label>
                      <input
                        defaultValue={authData?.userData?.room_number}
                        type="text"
                        placeholder="Enter your Room number"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("room_number", { required: true })}
                      />
                    </div>
                    <div className="w-full">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Floor Number <span className="text-meta-1">*</span>{" "}
                        {errors.floor_number && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Floor number is required.
                          </span>
                        )}
                      </label>
                      <input
                        defaultValue={authData?.userData?.floor_number}
                        type="text"
                        placeholder="Enter your Floor number"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("floor_number", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                      Hostel Name <span className="text-meta-1">*</span>{" "}
                      {errors.hostel_name && (
                        <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                          Hostel name is required.
                        </span>
                      )}
                    </label>
                    <input
                      defaultValue={authData?.userData?.hostel_name}
                      type="text"
                      placeholder="Enter your hostel name"
                      className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                      {...register("hostel_name", { required: true })}
                    />
                  </div>

                  <button className="mt-5 flex w-full justify-center bg-primary p-3 text-gray hover:bg-opacity-90 text-white font-gilroy-medium uppercase">
                    {_this?.buttonLoading ? (
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#ffff",
                          },
                        }}
                      >
                        <Spin indicator={<LoadingOutlined spin />} />
                      </ConfigProvider>
                    ) : (
                      "Save Address"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
