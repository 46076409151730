import { apiConnector, handleResponse } from "./core";
import { productEndpoints } from "./apis";

const {
  GET_ALL_PRODUCTS_API,
  GET_PRODUCTS_BY_CATEGORY_API,
  GET_ALL_PRODUCTS_BY_CATEGORY_API,
  CREATE_PRODUCT_API,
  DELETE_PRODUCT_API,
  UPDATE_PRODUCT_API,
  UPDATE_PRODUCT_AVAILABILITY_API,
  UPDATE_UNIVERSAL_AVAILABILITY_API,
  UPDATE_PRODUCT_QUANTITY_API,
} = productEndpoints;

const product = {
  GetAllProducts: async (data) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ALL_PRODUCTS_API, data);
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetAllProductsbyCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        GET_ALL_PRODUCTS_BY_CATEGORY_API,
        data
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetProductsByCategory: async (data) => {
    let response = null;
    try {
      response = await apiConnector(
        "GET",
        GET_PRODUCTS_BY_CATEGORY_API + `/${data.category_id}`
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  CreateProduct: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", CREATE_PRODUCT_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateProduct: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("PUT", UPDATE_PRODUCT_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  DeleteProduct: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("DELETE", DELETE_PRODUCT_API, data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ChangeAvailable: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "PUT",
        UPDATE_PRODUCT_AVAILABILITY_API + `/${data.product_id}`,
        null,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  ChangeUniversalAvailable: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "PUT",
        UPDATE_UNIVERSAL_AVAILABILITY_API,
        data,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  UpdateProductQuntity: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "PUT",
        UPDATE_PRODUCT_QUANTITY_API + `/${data.product_id}`,
        data,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default product;
