import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex flex-col items-center  w-full">
      <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] bg-[#02060c] px-[60px] py-[50px] flex flex-col">
        <h5 className="w-[170px] pt-[6px]">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="logo"
              className="h-[60px] object-fill mb-2"
              height="100"
              width="115"
            />
          </Link>
        </h5>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 mt-[8px]">
          <div>
            <ul>
              <li className="footer_links_heading font-gilroy-bold">Company</li>
              <li className="footer_links font-gilroy-medium">About</li>
              <li className="footer_links font-gilroy-medium">Team</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="footer_links_heading font-gilroy-bold">Legal</li>
              <li className="footer_links font-gilroy-medium">
                <Link to="/terms-conditions">Terms & Condintions</Link>
              </li>
              <li className="footer_links font-gilroy-medium">
                <Link to="/privacy-policy"> Privacy Policy</Link>
              </li>
              <li className="footer_links font-gilroy-medium">
                <Link to="/refund-policy"> Refund Policy</Link>
              </li>
              <li className="footer_links font-gilroy-medium">
                <Link to="/return-policy">Return Policy</Link>
              </li>
              <li className="footer_links font-gilroy-medium">
                <Link to="/shipping-policy">Shipping Policy</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="footer_links_heading font-gilroy-bold">
                Contact Us
              </li>
              <li className="footer_links font-gilroy-medium">
                Help & Support
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="footer_links_heading font-gilroy-bold">
                Founders
              </li>
              <li className="footer_links font-gilroy-medium">S</li>
            </ul>
          </div>
        </div>
        <div className="pt-[60px] pb-[36px] flex flex-col lg:flex-row justify-between gap-y-4">
          <div className="text-[#ffffffeb] font-gilroy-bold text-[16px] font-extralight leading-6 tracking-tight">
            © 2024 Martian Corporation. All Rights Reserved.
          </div>
          <div className="flex gap-x-1">
            <a href="#" rel="noreferrer" target="_blank">
              <img
                src="https://instamart-media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_48/Dweb/facebook.png"
                alt="Facebook"
                className="_2RiVR"
                style={{
                  width: "100%",
                  height: "24px",
                  objectFit: "contain",
                  background: "transparent",
                }}
              />
            </a>
            <a href="#" rel="noreferrer" target="_blank">
              <img
                src="https://instamart-media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_48/Dweb/Pinterest.png"
                alt="Pinterest"
                className="_2RiVR"
                style={{
                  width: "100%",
                  height: "24px",
                  objectFit: "contain",
                  background: "transparent",
                }}
              />
            </a>
            <a href="#" rel="noreferrer" target="_blank">
              <img
                src="https://instamart-media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_48/Dweb/Instagram.png"
                alt="Instagram"
                className="_2RiVR"
                style={{
                  width: "100%",
                  height: "24px",
                  objectFit: "contain",
                  background: "transparent",
                }}
              />
            </a>
            <a href="#" rel="noreferrer" target="_blank">
              <img
                src="https://instamart-media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_48/Dweb/Twitter.png"
                alt="Twitter"
                className="_2RiVR"
                style={{
                  width: "100%",
                  height: "24px",
                  objectFit: "contain",
                  background: "transparent",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
