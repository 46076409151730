import React, { useState } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import toast from "react-hot-toast";
import { setCookie } from "../../utils";

const AdminSetting = () => {
  const { authData, setAuthData } = useAuthDataStore((state) => ({
    authData: state.authData,
    setAuthData: state.setAuthData,
  }));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [hostels, setHostels] = useState(authData?.userData?.hostels);
  const [hostelInput, setHostelInput] = useState("");

  const updateProfileDetails = (formValue) => {
    const data = {
      ...formValue,
      hostels: hostels,
    };
    setButtonLoading(true);
    API.auth
      .UpdateUserDetails(authData.token, data)
      .then((response) => {
        if (response) {
          const data = {
            token: authData.token,
            userData: response,
          };
          setAuthData(data);
          setCookie(
            "authentications",
            { token: authData.token, userData: response },
            7
          );
          toast.success("Profile Details Updated");
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const changePassword = (formValue) => {
    setButtonLoading(true);
    API.auth
      .ChangePassword(authData.token, formValue)
      .then((response) => {
        if (response) {
          toast.success("Password Changed Successfully");
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const onHostelDelete = (val) => {
    setHostels(hostels.filter((item) => item !== val));
  };

  const onNewHostelAdd = () => {
    if (hostelInput === "") return toast.error("skills can't be empty");
    setHostels([...hostels, hostelInput]);
    setHostelInput("");
  };

  const _this = {
    buttonLoading,
    setButtonLoading,
    changePassword,
    updateProfileDetails,
    hostels,
    setHostels,
    hostelInput,
    setHostelInput,
    onHostelDelete,
    onNewHostelAdd,
  };
  return (
    <DefaultLayout>
      <Body {..._this} />
    </DefaultLayout>
  );
};

export default AdminSetting;
