import React from "react";
import { Table, Switch, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const columns = [
  {
    title: "Category",
    dataIndex: "category_name",
    fixed: "left",
    width: 300,
  },
  {
    title: "Product",
    dataIndex: "product",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "center",
  },
  {
    title: "Sub Category",
    dataIndex: "sub_category",
    align: "center",
  },
];

const CategoryTable = (_this) => {
  const createObject = (category, index) => {
    return {
      key: index,
      _id: category?._id,
      category_name: (
        <div className="flex items-center gap-x-3">
          <div className="h-[48px] w-[48px]">
            <img
              src={`${BASE_URL}${category?.category_image}`}
              alt="productImage"
              className="w-full h-full"
            />
          </div>
          <div>
            <p className="text-md font-satoshi">{category?.category_name}</p>
          </div>
        </div>
      ),
      product: <p className="font-satoshi">{category?.products.length}</p>,
      status: (
        <div className="flex gap-x-2 justify-center font-satoshi">
          <Switch
            value={category?.isAvailable}
            style={{
              backgroundColor: !category.isAvailable ? "#A3A3A4" : "#3C50E0",
            }}
            onClick={(checked) => {
              // console.log(`Switch toggled to ${checked}`);
              _this?.CangeCategoryStatus(category?._id);
            }}
          />
          {category.isAvailable ? (
            <span className="font-satoshi">Active</span>
          ) : (
            <span className="font-satoshi">Hidden</span>
          )}
        </div>
      ),
      action: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          <Link
            to={`/dashboard/manage-categories/edit/${category?._id}`}
            state={category}
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
            </svg>
          </Link>
          <button onClick={() => _this?.setDeleteModal(category?._id)}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"></path>
            </svg>
          </button>
        </div>
      ),
      sub_category: (
        <div className="flex items-center gap-x-2 font-satoshi">
          <p className="w-[50%] flex justify-end">
            {category?.subcategories.length}
          </p>
          <Link
            to={`/dashboard/manage-sub-category/${category?._id}`}
            className="w-[50%] flex justify-end"
          >
            <div className="p-2 bg-stroke rounded-full -rotate-90">
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 9"
                xmlns="http://www.w3.org/2000/svg"
                fill="#222325"
              >
                <path d="M.19 1.272.81.653a.375.375 0 0 1 .53 0L7 6.3 12.66.653a.375.375 0 0 1 .53 0l.62.62a.375.375 0 0 1 0 .53L7.264 8.346a.375.375 0 0 1-.53 0L.19 1.802a.375.375 0 0 1 0-.53Z"></path>
              </svg>
            </div>
          </Link>
        </div>
      ),
    };
  };
  const data = _this?.filteredItems.map((category, index) =>
    createObject(category, index)
  );
  return (
    <div className="p-4 md:p-6 2xl:p-10 font-satoshi">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default CategoryTable;
