import React from "react";
import Header from "./Header";
import ProductTable from "./ProductTable";
import DeleteModal from "./DeleteModal";

const Body = (_this) => {
  return (
    <>
      <ProductTable {..._this} />
      <DeleteModal {..._this} />
    </>
  );
};

export default Body;
