import create from "zustand";
import { getCookie } from "../utils";

const authStore = (set) => ({
  authData: getCookie("authentications") ? getCookie("authentications") : null,
  setAuthData: (data) => {
    set((state) => ({
      authData: data,
    }));
  },
  clearAuthData: () => {
    set((state) => ({
      authData: null,
    }));
  },
});

const useAuthDataStore = create(authStore);

export default useAuthDataStore;
