import React from "react";
import { Modal } from "antd";
import { FcCancel } from "react-icons/fc";
import { useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, ConfigProvider } from "antd";

const OrderCancelModal = (_this) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  return (
    <>
      <Modal
        title={
          <p className="font-satoshi flex items-center gap-x-2">
            <FcCancel className="font-bold text-danger" /> Cancel Order #
            {_this?.orderDetails?._id}
          </p>
        }
        open={_this?.cancelOrderModal}
        onCancel={() => _this?.setCancelOrderModal(false)}
        width={500}
        height={200}
        centered
        footer={null}
      >
        <form
          onSubmit={handleSubmit(_this?.handleOrderCancel)}
          className="font-satoshi font-medium h-full w-full pt-2"
        >
          <div className="mb-6">
            <textarea
              {...register("cancellation_reason", { required: true })}
              rows={6}
              placeholder="Reason of Cancel"
              className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
            ></textarea>
            {errors.cancellation_reason && (
              <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                Reason is required.
              </span>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="flex justify-center w-full text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90 font-satoshi rounded"
            >
              {_this?.buttonLoading ? (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#ffff",
                    },
                  }}
                >
                  <Spin indicator={<LoadingOutlined spin />} />
                </ConfigProvider>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default OrderCancelModal;
