import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import SerachResult from "./SerachResult";
import CategoryList from "./CategoryList";
import CartPopup from "../../common/CartPopup";

const Body = (_this) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col items-center ">
        <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] no-scrollbar">
          <div className="h-[48px] w-full text-left border border-primary rounded-[12px] bg-white mt-[16px]">
            <div className="h-full w-full px-[16px] rounded-[12px] relative flex justify-between items-center">
              <Link to={"/"} className="pr-2">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  aria-hidden="true"
                  strokeColor="rgba(2, 6, 12, 0.92)"
                  fillColor="rgba(2, 6, 12, 0.6)"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.4756 2.34685C11.1156 1.92807 10.4842 1.88047 10.0654 2.24053L3.94601 7.50195C3.935 7.51142 3.92385 7.52099 3.91258 7.53066C3.73776 7.6808 3.5354 7.85457 3.38506 8.02592C3.20644 8.22948 2.98298 8.5584 2.98298 9.01846C2.98298 9.47853 3.20644 9.80744 3.38505 10.011C3.53539 10.1824 3.73775 10.3561 3.91257 10.5063C3.92384 10.5159 3.93498 10.5255 3.946 10.535L10.01 15.7488C10.4287 16.1089 11.0601 16.0613 11.4202 15.6425C11.7802 15.2238 11.7327 14.5924 11.3139 14.2323L5.24991 9.01847L11.3693 3.75706C11.7881 3.397 11.8357 2.76563 11.4756 2.34685Z"
                    fill="rgba(2, 6, 12, 0.6)"
                    fill-opacity="0.92"
                  ></path>
                </svg>
              </Link>
              <input
                type="text"
                ref={inputRef}
                value={_this?.query}
                onChange={(e) => {
                  _this?.setQuery(e.target.value);
                }}
                placeholder="Type here to search"
                className="text-[14px] leading-[16px] font-gilroy-medium tracking-wider font-[200] text-text_secondary h-full w-full outline-none"
              />
              <div className="ml-[12px]">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  aria-hidden="true"
                  strokecolor="rgba(2, 6, 12, 0.92)"
                  fillcolor="rgba(2, 6, 12, 0.92)"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0998 8.84232C13.0998 11.7418 10.7493 14.0922 7.84989 14.0922C4.95046 14.0922 2.6 11.7418 2.6 8.84232C2.6 5.94289 4.95046 3.59243 7.84989 3.59243C10.7493 3.59243 13.0998 5.94289 13.0998 8.84232ZM12.1431 14.1802C10.9686 15.1261 9.47534 15.6922 7.84989 15.6922C4.0668 15.6922 1 12.6254 1 8.84232C1 5.05923 4.0668 1.99243 7.84989 1.99243C11.633 1.99243 14.6998 5.05923 14.6998 8.84232C14.6998 10.4974 14.1128 12.0153 13.1357 13.1993L18.319 17.9606C18.7226 18.3313 18.7359 18.9637 18.3483 19.3511C17.9634 19.7357 17.3365 19.7254 16.9645 19.3282L12.1431 14.1802Z"
                    fill="rgba(2, 6, 12, 0.92)"
                    fillOpacity="0.92"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <CategoryList {..._this} />
          <SerachResult {..._this} />
          {_this?.cartData && _this?.cartData.length > 0 && (
            <CartPopup isVisible={true} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Body;
