import React from "react";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const CategorySection = (_this) => {
  return (
    <div className="category-section w-full mb-9 h-full xxs:h-[calc(100vh-146px)] xs:h-full">
      <div className="p-[16px]">
        <div className="mb-[20px] flex items-center overflow-hidden">
          <div className=" text-[11px] text-nowrap leading-[16px] tracking-widest uppercase text-[#02060cbf] font-[600] font-gilroy-bold">
            Shop by Category
          </div>
          <div className="h-[1px] bg-custom-gradient w-[100%] ml-[12px] align-middle"></div>
        </div>
        <div className="overflow-x-hidden overflow-y-hidden grid grid-cols-4 gap-3 md:gap-6 sm:grid-cols-5  md:grid-cols-7">
          {_this?.categoryData &&
            _this?.categoryData.map((category, index) => {
              return (
                <Link
                  to={`/product-list`}
                  state={category?._id}
                  className="flex flex-col justify-center items-center"
                  key={index}
                >
                  <div className="h-[71px] w-[62px] xxs:h-[97px] xxs:w-[86px] xs:h-[110px] xs:w-[98px]">
                    <img
                      src={`${BASE_URL}${category?.category_image}`}
                      alt="vegitable"
                      className={`h-full w-full ${
                        !category?.isAvailable && "grayscale"
                      }`}
                    />
                  </div>
                  <div className="text-[13px] text-center font-gilroy-semibold font-[600] tracking-tight leading-4 text-text_dark opacity-[75%] pt-[8px] w-[100%] truncate">
                    {category?.category_name}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CategorySection;
