import React, { useEffect, useState } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import API from "../../api";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import useAuthDataStore from "../../app/authStore";
import toast from "react-hot-toast";

const SubCategory = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { category_id } = useParams();
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getSubCategory = () => {
    setLoading(true);
    API.subCategory
      .GetSubCategoryByCategory({ category_id })
      .then((response) => {
        if (response) {
          setSubCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSubCategory = () => {
    API.subCategory
      .DeleteSubCategory(authData?.token, { _id: deleteModal })
      .then((response) => {
        if (response) {
          removeSubCategoryById(deleteModal);
          toast.success("Sub Category Deleted");
        }
      })
      .finally(() => {
        setDeleteModal(false);
      });
  };

  function removeSubCategoryById(id) {
    const array = subCategoryData;
    const index = array.findIndex((obj) => obj._id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
    setSubCategoryData(array);
  }

  useEffect(() => {
    getSubCategory();
  }, []);

  const _this = {
    subCategoryData,
    setSubCategoryData,
    deleteModal,
    setDeleteModal,
    deleteSubCategory,
  };
  return (
    <DefaultLayout>
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default SubCategory;
