import React from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProductSelector = (_this) => {
  return (
    <div className="sm:px-5 col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4 h-full w-full">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Select Product
          </h5>
        </div>
      </div>

      <div className="mb-2 h-full">
        <ul className=" divide-gray-200 dark:divide-gray-700 h-[350px] overflow-y-scroll no-scrollbar cursor-pointer">
          {_this?.productData?.map((product, index) => {
            return (
              <li
                onClick={() => _this?.getProductAnalytics(product._id)}
                className={`py-3 px-2 sm:pb-4 ${
                  _this?.selectedProduct === product._id && "bg-cartBg/30"
                } hover:bg-cartBg/30 rounded-lg`}
                key={index}
              >
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="h-[48px] w-[48px]">
                    <img
                      src={`${BASE_URL}${product?.product_image[0]}`}
                      alt="productImage"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white font-satoshi">
                      {product?.product_name}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-satoshi">
                      {product?.product_category?.category_name}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductSelector;
