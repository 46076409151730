import React from "react";
import useCartDataStore from "../../app/cartStore";

import HomeNavbar from "./HomeNavbar";
import CategorySection from "./CategorySection";
import Footer from "../../components/Footer";
import CartPopup from "../../common/CartPopup";

const Body = (_this) => {
  const { cartData } = useCartDataStore((state) => ({
    cartData: state.cartData,
  }));
  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh]">
        <HomeNavbar />
        <CategorySection {..._this} />
        <Footer />
        {cartData && cartData.length > 0 && <CartPopup isVisible={true} />}
      </div>
    </div>
  );
};

export default Body;
