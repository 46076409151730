import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin, ConfigProvider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const options = {
  chart: {
    height: 350,
    type: "line",
  },
  colors: ["#3C50E0", "#80CAEE"],
  stroke: {
    width: [0, 4],
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
    style: {
      fontSize: "12px",
    },
    background: {
      enabled: true,
      foreColor: "#3C50E0",
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#3C50E0",
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10,
      borderRadiusApplication: "end", // 'around', 'end'
      borderRadiusWhenStacked: "last", // 'all', 'last'
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  xaxis: {
    type: "Month",
  },
  yaxis: [
    {
      title: {
        text: "Amount",
      },
    },
    {
      opposite: true,
      title: {
        text: "Product Ordered",
      },
    },
  ],
  fill: {
    opacity: 1,
  },
};

const ChartOne = (_this) => {
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8 h-full">
      {/* Header section */}
      <div className="flex flex-wrap items-start justify-center gap-3 sm:flex-nowrap">
        <h5 className="text-xl font-semibold text-black dark:text-white">
          Analytics
        </h5>
      </div>

      {/* Chart section */}
      <div className="h-full">
        <div id="chartOne" className="-ml-5 h-full">
          {_this?.buttonLoading ? (
            <div className="min-h-full w-full flex items-center justify-center">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#3C50E0",
                  },
                }}
              >
                <div className="flex flex-col gap-y-2 items-center gap-x-2">
                  <Spin size="large" indicator={<LoadingOutlined spin />} />{" "}
                  Loading...
                </div>
              </ConfigProvider>
            </div>
          ) : (
            <ReactApexChart
              options={options}
              series={_this?.productOrderCount}
              type="bar"
              height={350}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartOne;
