import React, { useEffect, useState } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Header from "./Header";
import Loader from "../../common/Loader";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";

const UserAnalytics = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [customersData, setCustomersData] = useState([]);

  const getTopOrderingCustomers = (startDate, endDate, reset) => {
    setLoading(true);
    let data = {};
    if (startDate !== null && startDate !== null && !reset) {
      data = {
        start_date: startDate,
        end_date: endDate,
      };
    }

    API.analytics
      .GetTopOrderingUsers(authData.token, data)
      .then((response) => {
        if (response) {
          setCustomersData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTopOrderingCustomers();
  }, []);

  const _this = {
    loading,
    setLoading,
    customersData,
    setCustomersData,
    getTopOrderingCustomers,
  };
  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default UserAnalytics;
