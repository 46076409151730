import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import Loader from "../../common/Loader";
import toast from "react-hot-toast";
import Header from "./Header";
import io from "socket.io-client";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io.connect(BASE_URL);

const Category = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetCategories()
      .then((response) => {
        if (response) {
          setCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCategory = (_id) => {
    setLoading(true);
    API.category
      .DeleteCategory(authData?.token, { _id })
      .then((response) => {
        if (response) {
          removeCategoryById(_id);
          toast.success("Category Deleted");
        }
      })
      .finally(() => {
        setLoading(false);
        setDeleteModal(false);
      });
  };

  function removeCategoryById(id) {
    const array = categoryData;
    const index = array.findIndex((obj) => obj._id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
    setCategoryData(array);
  }

  const CangeCategoryStatus = (_id) => {
    API.category
      .UpdateAvailability(authData.token, { _id })
      .then((response) => {
        updateCategoryById(_id, response);

        socket.emit("send_update_category_availability", response);
      })
      .finally(() => {});
  };

  const updateCategoryById = (_id, response) => {
    setCategoryData((category) =>
      category.map((item) =>
        item._id === _id ? { ...item, ...response } : item
      )
    );
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      if (query === "") {
        setFilteredItems(categoryData);
      } else {
        const queryWords = query.toLowerCase().split(" ").filter(Boolean);
        const filtered = categoryData.filter((item) =>
          queryWords.every((word) =>
            Object.values(item).some((value) =>
              String(value).toLowerCase().includes(word)
            )
          )
        );
        setFilteredItems(filtered);
      }
    };

    handleSearch();
  }, [query, categoryData]);

  const _this = {
    deleteModal,
    setDeleteModal,
    categoryData,
    setCategoryData,
    deleteCategory,
    CangeCategoryStatus,
    query,
    setQuery,
    filteredItems,
    setFilteredItems,
  };
  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default Category;
