import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import useAuthDataStore from "../../app/authStore";
import API from "../../api";
import Loader from "../../common/Loader";
import Header from "./Header";

const Dashboard = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState(null);
  const [categoryAnalytics, setCategoryAnalytics] = useState([]);
  const [chatOne, setChatOne] = useState([]);
  const [filterDate, setFilterDate] = useState({});

  const getAnalytics = () => {
    setLoading(true);
    API.analytics
      .GetAnalytics(authData.token)
      .then((response) => {
        if (response) {
          setChatOne(response?.monthlyStats);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategoryAnalytics = () => {
    setLoading(true);
    API.analytics
      .GetCategoryAnalytics(authData.token)
      .then((response) => {
        if (response) {
          setCategoryAnalytics(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrderAnalytics = (fromDate, toDate) => {
    setLoading(true);
    const data = {
      start_date: fromDate,
      end_date: toDate,
    };
    API.analytics
      .GetOrderAnalytics(authData.token, data)
      .then((response) => {
        if (response) {
          setAnalytics(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterOrderAnalytics = (fromDate, toDate, reset) => {
    getOrderAnalytics(fromDate, toDate);
  };

  useEffect(() => {
    getAnalytics();
    getCategoryAnalytics();
    getOrderAnalytics();
  }, []);

  const _this = {
    loading,
    setLoading,
    analytics,
    setAnalytics,
    chatOne,
    setChatOne,
    categoryAnalytics,
    setCategoryAnalytics,
    filterOrderAnalytics,
  };

  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default Dashboard;
