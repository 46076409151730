import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import EnterOtp from "./EnterOtp";

const Body = (_this) => {
  let password;
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  password = watch("password", "");

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        name: "",
        registration_number: "",
        email: "",
        phone_number: "",
        room_number: "",
        floor_number: "",
        hostel_name: "",
        password: "",
        confirm_password: "",
      });
    }
  }, [reset, isSubmitSuccessful]);
  return (
    <>
      {_this.otpReceived === false ? (
        <div className="flex flex-col items-center">
          <div className="w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] h-full">
            <div className="h-full flex items-center lg:my-7">
              {/* <!-- Sign In Form --> */}
              <div className=" bg-white w-full lg:w-8/12 mx-auto border">
                <div className="flex flex-col items-center justify-center py-4 border-b">
                  <h3 className="text-black dark:text-white font-gilroy-medium text-title-md font-medium">
                    Sign Up
                  </h3>
                  <p className="text-strokedark/65 font-gilroy-light text-center">
                    Please fill your form to create your account
                  </p>
                </div>
                <form className="" onSubmit={handleSubmit(_this.onSignUp)}>
                  <div className="p-6">
                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Name <span className="text-meta-1">*</span>{" "}
                        {errors.name && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Name is required.
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your full name"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("name", { required: true })}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Registration Number{" "}
                        <span className="text-meta-1">*</span>{" "}
                        {errors.registration_number && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Registration is required.
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your registration"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("registration_number", { required: true })}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Email <span className="text-meta-1">*</span>{" "}
                        {errors.email && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Email is required.
                          </span>
                        )}
                      </label>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("email", { required: true })}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Phone Number <span className="text-meta-1">*</span>{" "}
                        {errors.phone_number && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Phone number is required.
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your phone number"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("phone_number", { required: true })}
                      />
                    </div>

                    <div className="w-full flex flex-col lg:flex-row justify-between gap-3 mb-4">
                      <div className="w-full">
                        <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                          Room Number <span className="text-meta-1">*</span>{" "}
                          {errors.room_number && (
                            <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                              Room number is required.
                            </span>
                          )}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your Room number"
                          className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                          {...register("room_number", { required: true })}
                        />
                      </div>
                      <div className="w-full">
                        <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                          Floor Number <span className="text-meta-1">*</span>{" "}
                          {errors.floor_number && (
                            <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                              Floor number is required.
                            </span>
                          )}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your Floor number"
                          className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                          {...register("floor_number", { required: true })}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Hostel Name <span className="text-meta-1">*</span>{" "}
                        {errors.hostel_name && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            Hostel name is required.
                          </span>
                        )}
                      </label>
                      <div className="relative z-20 bg-transparent dark:bg-form-input">
                        <select
                          name="hostel_name"
                          {...register("hostel_name", { required: true })}
                          onChange={(e) => {
                            setValue("hostel_name", e.target.value);
                          }}
                          className={` appearance-none w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light`}
                        >
                          <option
                            value=""
                            selected
                            disabled
                            className="text-white dark:text-bodydark"
                          >
                            Select your Hostel
                          </option>
                          {_this?.hostelsOptions &&
                            _this?.hostelsOptions.map((hostel, index) => {
                              return (
                                <option
                                  key={index}
                                  value={hostel}
                                  className="text-body dark:text-bodydark"
                                >
                                  {hostel}
                                </option>
                              );
                            })}
                        </select>

                        <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                          <svg
                            className="fill-current"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Password <span className="text-meta-1">*</span>{" "}
                        {errors.password?.message && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            {errors.password?.message}
                          </span>
                        )}
                      </label>
                      <input
                        type="password"
                        placeholder="Enter password"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("password", {
                          required: "Password is required.",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,24})(?=.*[0-9])(?=.*[@$!%*#?&])/,
                            message:
                              "Password must be 8-24 chars, upper & lowercase, number, special char",
                          },
                        })}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 block text-black dark:text-white font-gilroy-light">
                        Confirm Password <span className="text-meta-1">*</span>
                        {errors.confirm_password?.message && (
                          <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                            {errors.confirm_password?.message}
                          </span>
                        )}
                      </label>
                      <input
                        type="password"
                        placeholder="Enter confirm password"
                        className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary placeholder:font-gilroy-light"
                        {...register("confirm_password", {
                          required: true,
                          validate: (val) => {
                            if (val === "") {
                              return "Confirm password is required.";
                            } else if (watch("password") !== val) {
                              return "Password and Confirm password did not match";
                            }
                          },
                        })}
                      />
                    </div>

                    <button className="mt-5 flex w-full justify-center bg-primary p-3 text-gray hover:bg-opacity-90 text-white font-gilroy-medium uppercase">
                      Sign Up
                    </button>
                    <div className="flex justify-center pt-3">
                      <span className="text-md font-gilroy-light">
                        Already have an account?
                      </span>
                      <Link
                        to="/sign-in"
                        className="text-md font-gilroy-medium text-primary pl-1 hover:underline"
                      >
                        Sign in
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : _this.otpReceived === true ? (
        <EnterOtp _this={_this} />
      ) : (
        <div className="min-h-screen flex items-center justify-center">
          <h1 className="text-primary font-semibold">
            {" "}
            You're being redirected to dashboard!
          </h1>
        </div>
      )}
    </>
  );
};

export default Body;
