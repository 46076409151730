import React, { useEffect, useRef, useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const CategorySidebar = (_this) => {
  const parentRef = useRef(null);
  const linkRefs = useRef({});
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const handleChildClick = (childRef) => {
    if (parentRef.current && childRef) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const childRect = childRef.getBoundingClientRect();
      // Calculate the distance in pixels
      const distanceFromParent =
        childRect.top - parentRect.top + parentRef.current.scrollTop;
      document.documentElement.style.setProperty(
        "--my-variable",
        `${distanceFromParent + 25}px`
      );
    }
  };
  useEffect(() => {
    if (_this?.category?._id && linkRefs.current[_this?.category?._id]) {
      handleChildClick(linkRefs.current[_this?.category?._id]);
      const parentRect = parentRef.current.getBoundingClientRect();
      const childRect =
        linkRefs.current[_this?.category?._id].getBoundingClientRect();

      if (
        childRect.top > parentRect.bottom ||
        childRect.bottom < parentRect.top
      ) {
        // The element is outside of the viewport, scroll to it
        linkRefs.current[_this?.category?._id].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [_this?.category?._id, _this?.categoryData]);

  return (
    <ul
      ref={parentRef}
      className="category-sidebar relative w-[20%] pb-[80px] px-[4px] rounded-br-none rounded-tl-none rounded-tr-[8px] bg-white min-h-[calc(100vh-68px)] max-h-[calc(100vh-68px)] flex flex-col overflow-y-scroll no-scrollbar"
    >
      {_this?.categoryData &&
        _this?.categoryData.map((category, index) => {
          return (
            <>
              <div
                key={index}
                onClick={(e) => {
                  handleChildClick(e.currentTarget);
                  _this?.setProductData(category?.products);
                  _this?.setCategory(category);
                  setSelectedSubCategory("");
                }}
                ref={(el) => (linkRefs.current[category._id] = el)}
                className="bg-white z-20"
              >
                <li className="flex flex-col items-center justify-center mt-[12px] py-[12px] cursor-pointer">
                  <div>
                    <div className="flex flex-col items-center justify-center">
                      <div
                        className={`relative rounded-[8px] w-[40px] h-[48px] md:w-[47px] md:h-[55px] mb-[8px] bg-[#f0f0f5] border border-[#e2e2e7] z-[1] ${
                          category?._id === _this?.category?._id &&
                          "scale-110 xs:scale-[1.15]"
                        } transition-all duration-300 ease-in-out`}
                      >
                        <img
                          src={`${BASE_URL}${category?.category_image}`}
                          alt="category_image"
                          className={`w-full h-full object-fill ${
                            !category?.isAvailable && "grayscale"
                          }`}
                        />
                      </div>
                      <div
                        className={`font-gilroy-bold font-normal text-[13px] leading-[16px] tracking-[-0.3px] ${
                          category?._id === _this?.category?._id
                            ? "text-[#9e1e62]"
                            : "text-[#02060c99]"
                        } overflow-hidden w-full break-words text-center`}
                      >
                        {category?.category_name}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div
                className={`${
                  _this?.category?.subcategories.length > 0
                    ? "translate-y-[0%]"
                    : "-translate-y-[100%] opacity-0 h-0 overflow-y-hidden"
                } transition-all duration-300 bg-cartBg/30 shadow-inner`}
              >
                {category?.subcategories.length > 0 &&
                  category?.subcategories?.map((subcategories, index) => {
                    return (
                      <li
                        key={index}
                        className="flex flex-col items-center justify-center mt-[12px] py-[12px] cursor-pointer"
                        onClick={() => {
                          setSelectedSubCategory(subcategories?._id);
                          const products = _this?.allProductsData.filter(
                            (product) =>
                              product.product_subcategory === subcategories?._id
                          );
                          _this?.setProductData(products);
                        }}
                      >
                        <div>
                          <div className="flex flex-col items-center justify-center">
                            <div
                              className={`relative rounded-[8px] w-[40px] h-[48px] md:w-[47px] md:h-[55px] mb-[8px] bg-[#f0f0f5] border border-[#e2e2e7] z-[1] ${
                                subcategories?._id === selectedSubCategory &&
                                "scale-110 xs:scale-[1.15]"
                              } transition-all duration-300 ease-in-out`}
                            >
                              <img
                                src={`${BASE_URL}${subcategories?.subcategory_Image}`}
                                alt="category_image"
                                className="w-full h-full object-fill"
                              />
                            </div>
                            <div
                              className={`font-gilroy-bold font-normal text-[13px] leading-[16px] tracking-[-0.3px] ${
                                subcategories?._id === selectedSubCategory
                                  ? "text-[#9e1e62]"
                                  : "text-[#02060c99]"
                              } overflow-hidden w-full break-words text-center`}
                            >
                              {subcategories?.subcategory_name}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </div>
            </>
          );
        })}
    </ul>
  );
};

export default CategorySidebar;
