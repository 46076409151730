import React from "react";
import { Modal } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";

const DeleteModal = (_this) => {
  return (
    <>
      <Modal
        title={
          <p className="font-satoshi flex items-center gap-x-2">
            <RiDeleteBin6Line className="font-bold text-danger" /> Delete Sub
            Category
          </p>
        }
        open={_this?.deleteModal}
        onCancel={() => _this?.setDeleteModal(false)}
        width={300}
        height={200}
        centered
        footer={
          <div className="flex justify-end">
            <button
              onClick={() => {
                _this?.deleteSubCategory(_this?.deleteModal);
              }}
              className="flex justify-center text-white bg-dashboardPrimary p-2 font-medium text-gray hover:bg-opacity-90 font-satoshi rounded"
            >
              Delete
            </button>
          </div>
        }
      >
        <div className="font-satoshi font-medium h-full w-ful ml-6">
          Are you sure?
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
