import React from "react";
import ChartOne from "./ChartOne";
import ProductTable from "./ProductsTable";
import ProductSelector from "./ProductSelector";

const Body = (_this) => {
  return (
    <>
      <div className=" grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 p-4 md:p-6 2xl:p-10 h-full">
        <ChartOne {..._this} />
        <ProductSelector {..._this} />
      </div>
      <ProductTable {..._this} />
    </>
  );
};

export default Body;
