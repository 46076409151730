import React, { useState, useEffect } from "react";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Body from "./Body";
import useDashboardLoading from "../../app/dashboardLoading";
import API from "../../api";
import Loader from "../../common/Loader";

const AddProduct = () => {
  const { loading, setLoading } = useDashboardLoading((state) => ({
    loading: state.loading,
    setLoading: state.setLoading,
  }));
  const [categoryData, setCategoryData] = useState([]);

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const _this = {
    categoryData,
    setCategoryData,
  };
  return (
    <DefaultLayout>
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default AddProduct;
