import React, { useEffect, useState } from "react";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import toast from "react-hot-toast";
import Loader from "../../common/Loader";
import Header from "./Header";

const AllOrders = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [activeOrder, setActiveOrder] = useState([]);
  const [packedOrder, setPackedOrder] = useState([]);
  const [deliveredOrder, setDeliveredOrder] = useState([]);
  const [cancelledOrder, setCancelledOrder] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderTab, setOrderTab] = useState(1);
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getAllOrder = () => {
    setLoading(true);
    API.order
      .GetAllOrders(authData.token)
      .then((response) => {
        if (response) {
          setOrderData(response.ActiveOrders);
          setActiveOrder(response.ActiveOrders);
          setPackedOrder(response.PackedOrders);
          setDeliveredOrder(response.DeliveredOrders);
          setCancelledOrder(response.CancelledOrders);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ChangeStatus = (order_id, order_status) => {
    setLoading(true);
    API.order
      .ChangeOrderStatus(authData.token, { order_status, order_id })
      .then((response) => {
        if (response) {
          moveData(order_id, order_status, response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const ChangeAmountPaidHandler = (order_id) => {
    setButtonLoading(true);
    API.order
      .ChangeAmountPaid(authData.token, { order_id })
      .then((response) => {
        if (response) {
          replaceObjectInArray(response);
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const replaceObjectInArray = (newObject) => {
    setOrderData((prevObjects) =>
      prevObjects.map((item) => (item._id === newObject._id ? newObject : item))
    );
  };

  const moveData = (order_id, order_status, response) => {
    if (order_status === "Packed") {
      const index = activeOrder.findIndex((order) => order._id === order_id);
      activeOrder.splice(index, 1);
      setActiveOrder([...activeOrder]);
      setPackedOrder([...packedOrder, response]);
      toast.success("Order Packed");
    } else if (order_status === "Delivered") {
      const index = packedOrder.findIndex((order) => order._id === order_id);
      packedOrder.splice(index, 1);
      setPackedOrder([...packedOrder]);
      setDeliveredOrder([...deliveredOrder, response]);
      toast.success("Order Delivered");
    }
  };

  const onChangeTab = (tab) => {
    setOrderTab(tab);
    if (tab === 1) {
      setOrderData(activeOrder);
    } else if (tab === 2) {
      setOrderData(packedOrder);
    } else if (tab === 3) {
      setOrderData(deliveredOrder);
    } else {
      setOrderData(cancelledOrder);
    }
  };

  const filterOrderByDate = (fromDate, toDate, reset) => {
    if (!reset) {
      // Convert fromDate and toDate to Date objects
      const from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);

      // Convert toDate to a Date object and set time to 11:59 PM
      const to = new Date(toDate);
      to.setHours(23, 59, 59, 999);
      const data = orderData;
      // Filter the data
      const orders = data.filter((order) => {
        const createdAt = new Date(order.createdAt);
        return createdAt >= from && createdAt <= to;
      });
      setOrderData(orders);
    } else {
      if (orderTab === 1) {
        setOrderData(activeOrder);
      } else if (orderTab === 2) {
        setOrderData(packedOrder);
      } else if (orderTab === 3) {
        setOrderData(deliveredOrder);
      } else {
        setOrderData(cancelledOrder);
      }
    }
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      if (query === "") {
        setFilteredItems(orderData);
      } else {
        const queryWords = query.toLowerCase().split(" ").filter(Boolean);
        const filtered = orderData.filter((item) =>
          queryWords.every((word) =>
            Object.values(item).some((value) =>
              String(value).toLowerCase().includes(word)
            )
          )
        );
        setFilteredItems(filtered);
      }
    };

    handleSearch();
  }, [query, orderData]);

  const _this = {
    loading,
    setLoading,
    activeOrder,
    setPackedOrder,
    packedOrder,
    setPackedOrder,
    deliveredOrder,
    setDeliveredOrder,
    cancelledOrder,
    setCancelledOrder,
    orderTab,
    setOrderTab,
    onChangeTab,
    orderData,
    setOrderData,
    filterOrderByDate,
    ChangeStatus,
    query,
    setQuery,
    filteredItems,
    setFilteredItems,
    ChangeAmountPaidHandler,
    buttonLoading,
    setButtonLoading,
  };
  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default AllOrders;
