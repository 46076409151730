import React from "react";
import { Table, Switch, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const columns = [
  {
    title: "Product",
    dataIndex: "product_name",
    fixed: "left",
    width: 300,
  },
  {
    title: "Price",
    dataIndex: "product_price",
    align: "center",
  },
  {
    title: "Inventory",
    dataIndex: "product_quantity",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "center",
  },
];

const ProductTable = (_this) => {
  const createObject = (product, index) => {
    return {
      key: index,
      _id: product?._id,
      product_name: (
        <div className="flex items-center gap-x-3">
          <div className="h-[48px] w-[48px]">
            <img
              src={`${BASE_URL}${product?.product_image[0]}`}
              alt="productImage"
            />
          </div>
          <div>
            <p className="text-sm">{product?.product_name}</p>
            <p className="text-xs text-slate-600">
              {product?.product_category?.category_name}
            </p>
          </div>
        </div>
      ),
      product_price: (
        <div className="font-satoshi flex justify-center gap-x-3">
          <p className="rs font-satoshi">{product?.discounted_price}</p>
          <p className="rs font-satoshi text-strokedark/70 line-through">
            {product?.product_price}
          </p>
        </div>
      ),
      product_quantity: (
        <p className="font-satoshi">{product?.product_quantity}</p>
      ),
      status: (
        <div className="flex  gap-x-3 justify-center">
          <Switch
            value={
              !product?.universal_availability ? false : product.is_available
            }
            unCheckedChildren={false}
            style={{
              backgroundColor: !product?.universal_availability
                ? "#A3A3A4"
                : !product.is_available
                ? "#A3A3A4"
                : "#3C50E0",
            }}
            onClick={(checked) => {
              if (product?.universal_availability) {
                _this?.ChangeStatus(product, checked);
              }
            }}
          />
          {!product?.universal_availability ? (
            false
          ) : product.is_available ? (
            <span className="font-satoshi">Active</span>
          ) : (
            <span className="font-satoshi">Hidden</span>
          )}
        </div>
      ),
      action: (
        <div className="flex justify-center gap-x-4">
          <Link
            to={`/dashboard/manage-product/edit/${product?._id}`}
            state={product}
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
            </svg>
          </Link>
          <button
            onClick={() => {
              _this?.setDeleteModal(product);
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"></path>
            </svg>
          </button>
        </div>
      ),
    };
  };

  const data = _this?.filteredItems.map((product, index) =>
    createObject(product, index)
  );
  return (
    <div className="p-4 md:p-6 2xl:p-10">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default ProductTable;
