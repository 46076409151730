import React, { useState, useEffect } from "react";
import Body from "./Body";
import useCategoryStore from "../../app/categoryStore";
import useCartDataStore from "../../app/cartStore";
import API from "../../api";

const SearchPage = () => {
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const { cartData, addToCart, increaseQuantity, decreaseQuantity } =
    useCartDataStore((state) => ({
      cartData: state.cartData,
      addToCart: state.addToCart,
      increaseQuantity: state.increaseQuantity,
      decreaseQuantity: state.decreaseQuantity,
    }));
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [allProductsData, setAllProductsData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const handleIncrease = (productId) => {
    increaseQuantity(productId);
  };

  const handleDecrease = (productId) => {
    decreaseQuantity(productId);
  };

  const isProductInCart = (productId) => {
    const product = cartData?.find((item) => item.product._id === productId);
    return product ? product.quantity : false;
  };

  const getAllProducts = () => {
    setLoading(true);
    API.product
      .GetAllProducts()
      .then((response) => {
        if (response) {
          setAllProductsData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    const handleSearch = () => {
      setSearchLoading(true);
      if (query === "") {
        setFilteredItems([]);
      } else {
        const queryWords = query.toLowerCase().split(" ").filter(Boolean);
        const filtered = allProductsData.filter((product) =>
          matchesQuery(product, queryWords)
        );
        setFilteredItems(filtered);
      }
      setSearchLoading(false);
    };

    handleSearch();
  }, [query]);

  const matchesQuery = (product, queryWords) => {
    return queryWords.every((word) => matchesRecursive(product, word));
  };

  const matchesRecursive = (obj, word) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (matchesRecursive(obj[key], word)) {
          return true;
        }
      } else if (
        typeof obj[key] === "string" &&
        obj[key].toLowerCase().includes(word)
      ) {
        return true;
      }
    }
    return false;
  };

  const _this = {
    categoryData,
    setCategoryData,
    allProductsData,
    setAllProductsData,
    loading,
    setLoading,
    query,
    setQuery,
    searchLoading,
    setSearchLoading,
    filteredItems,
    setFilteredItems,
    handleAddToCart,
    handleIncrease,
    handleDecrease,
    cartData,
    isProductInCart,
  };
  return <Body {..._this} />;
};

export default SearchPage;
