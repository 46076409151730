import React from "react";
import { Table, Spin, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
const columns = [
  {
    title: "Order Id",
    dataIndex: "order_id",
    align: "center",
  },
  {
    title: "Date",
    dataIndex: "order_date",
    align: "center",
  },
  {
    title: "Customer",
    dataIndex: "order_user",
    align: "center",
  },
  {
    title: "Items",
    dataIndex: "order_items",
    align: "center",
  },
  {
    title: "Payment",
    dataIndex: "order_payment",
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "order_amount",
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "order_status",
    align: "center",
  },
  {
    title: "Payment Status",
    dataIndex: "payment_status",
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "center",
  },
];

const OrderTable = (_this) => {
  const filterColumns = () => {
    let filteredColumns = columns;
    if (_this?.orderTab === 1) {
    } else if (_this?.orderTab === 2) {
      // setOrderData(packedOrder);
    } else if (_this?.orderTab === 3) {
      filteredColumns = columns.filter((col) => {
        if (col.dataIndex === "action") {
          return false;
        }
        return true;
      });
    } else {
      filteredColumns = columns.filter((col) => {
        if (col.dataIndex === "action") {
          return false;
        }
        return true;
      });
    }
    return filteredColumns;
  };

  const createObject = (order, index) => {
    return {
      key: index,
      _id: order?._id,
      order_id: (
        <Link to={`/dashboard/order-details/${order?._id}`}>
          <p className="flex justify-center items-center gap-x-3 text-dashboardPrimary hover:underline hover:text-dashboardPrimary font-satoshi truncate">
            {order?.order_id}
          </p>
        </Link>
      ),
      order_date: (
        <div className="font-satoshi flex justify-center gap-x-3">
          <p className="font-satoshi">
            {moment(order?.createdAt).format("MMMM Do YYYY, h:mm a")}
          </p>
        </div>
      ),
      order_user: <p className="font-satoshi">{order?.user?.name}</p>,
      order_items: (
        <div className="flex  gap-x-3 justify-center font-satoshi">
          {order?.total_items}
        </div>
      ),
      order_payment: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          {order?.payment_mod}
        </div>
      ),
      order_amount: (
        <div className="flex justify-center  rs font-satoshi">
          {order?.discounted_amount}
        </div>
      ),
      order_status: (
        <div className="flex justify-center gap-x-4 font-satoshi">
          {order?.order_status}
        </div>
      ),
      payment_status: (
        <div
          className={` font-satoshi rounded-2xl ${
            order?.amount_paid === false
              ? "bg-primary/20 text-primary"
              : "bg-green-100"
          }`}
        >
          {order?.amount_paid === false ? "Not Paid" : "Paid"}
        </div>
      ),
      action: (
        <div className="flex justify-center font-satoshi">
          {_this?.orderTab === 1 && (
            <button
              onClick={() => {
                _this?.ChangeStatus(order?._id, "Packed");
              }}
              className="py-1 px-2 bg-white text-dashboardPrimary border border-dashboardPrimary font-satoshi rounded-xs hover:bg-dashboardPrimary hover:text-white transition-all duration-100"
            >
              Pack Order
            </button>
          )}
          {_this?.orderTab === 2 && order?.amount_paid === false && (
            <button
              onClick={() => {
                _this?.ChangeAmountPaidHandler(order?._id);
              }}
              className="py-1 px-2 bg-white text-dashboardPrimary border border-dashboardPrimary font-satoshi rounded-xs hover:bg-dashboardPrimary hover:text-white transition-all duration-100"
            >
              {_this?.buttonLoading ? (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#ffff",
                    },
                  }}
                >
                  <div className="flex items-center gap-x-2">
                    <Spin indicator={<LoadingOutlined spin />} /> Loading...
                  </div>
                </ConfigProvider>
              ) : (
                "Cash Collected"
              )}
            </button>
          )}
          {_this?.orderTab === 2 && order?.amount_paid === true && (
            <button
              onClick={() => {
                _this?.ChangeStatus(order?._id, "Delivered");
              }}
              className="py-1 px-2 bg-white text-dashboardPrimary border border-dashboardPrimary font-satoshi rounded-xs hover:bg-dashboardPrimary hover:text-white transition-all duration-100"
            >
              Deliver Order
            </button>
          )}
        </div>
      ),
    };
  };
  const data = _this?.filteredItems?.map((order, index) =>
    createObject(order, index)
  );

  return (
    <div className="p-4 md:p-6 2xl:p-10">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={filterColumns()}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default OrderTable;
