import React from "react";
import UserForm from "./UserForm";
import ChangePassword from "./ChangePassword";

const Body = (_this) => {
  return (
    <>
      <div className="p-4 md:p-6 2xl:p-10 flex flex-col gap-y-6">
        <UserForm {..._this} />
        <ChangePassword {..._this} />
      </div>
    </>
  );
};

export default Body;
