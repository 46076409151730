import React from "react";
import { Table, Switch, ConfigProvider } from "antd";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const columns = [
  {
    title: "Product",
    dataIndex: "product_name",
    fixed: "left",
    width: 400,
  },
  {
    title: "Price",
    dataIndex: "product_price",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "order_count",
    align: "center",
  },
  {
    title: "Order Amount",
    dataIndex: "order_amount",
    align: "center",
  },
];

const ProductTable = (_this) => {
  const createObject = (product, index) => {
    return {
      key: index,
      _id: product?._id,
      product_name: (
        <div className="flex items-center gap-x-3">
          <div className="h-[48px] w-[48px]">
            <img
              src={`${BASE_URL}${product?.product_image[0]}`}
              alt="productImage"
            />
          </div>
          <div>
            <p className="text-sm">{product?.product_name}</p>
            <p className="text-xs text-slate-600">
              {product?.product_category?.category_name}
            </p>
          </div>
        </div>
      ),
      product_price: (
        <div className="font-satoshi flex justify-center gap-x-3">
          <p className="rs font-satoshi">{product?.discounted_price}</p>
          <p className="rs font-satoshi text-strokedark/70 line-through">
            {product?.product_price}
          </p>
        </div>
      ),
      order_count: <p className="font-satoshi">{product?.count}</p>,
      order_amount: (
        <p className="font-satoshi rs">
          {product?.count * product?.discounted_price}
        </p>
      ),
    };
  };

  const data = _this?.topSellingProducts.map((product, index) =>
    createObject(product, index)
  );
  return (
    <div className="p-4 md:p-6 2xl:p-10">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              handleBg: "#24303F",
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: 800,
          }}
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default ProductTable;
