import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

import Loader from "./common/Loader";

//Routes Checker
import AdminRoutes from "./components/RoutesChecking/AdminRoutes";
import PublicRoutes from "./components/RoutesChecking/PublicRoutes";
import PrivateRoutes from "./components/RoutesChecking/PrivateRoutes";

// Public Pages
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import Cart from "./pages/Cart";
import SearchPage from "./pages/SearchPage";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ShippingPolicy from "./pages/ ShippingPolicy";

// Private Pages
import Dashboard from "./pages/Dashboard";
import Product from "./pages/Products";
import AddProduct from "./pages/AddProduct";
import Category from "./pages/Category";
import AddCategory from "./pages/AddCategory";
import EditCategory from "./pages/EditCategory";
import EditProduct from "./pages/EditProduct";
import AdminSetting from "./pages/AdminSetting";
import AllOrders from "./pages/AllOrders";
import OrderDetails from "./pages/OrderDetails";
import SubCategory from "./pages/SubCategory";
import AddSubCategory from "./pages/AddSubCategory";
import EditSubCategory from "./pages/EditSubCategory";
import UserAnalytics from "./pages/UserAnalytics";
import ProductAnalytics from "./pages/ProductAnalytics";

// Customers Page
import CustomersProfile from "./pages/CustomersProfile";
import CustomersOrders from "./pages/CustomersOrders";
import CustomerOrderDetails from "./pages/CustomerOrderDetails";
import CustomerMangeAddress from "./pages/CustomerManageAddress";
import CustomerEditProfile from "./pages/CustomerEditProfile";

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 500);
  return (
    <>
      {loading && <Loader dashboard={false} />}
      <div
        className={`${
          loading ? "hidden" : "flex"
        } min-h-screen max-w-screen scroll-smooth flex-col select-none`}
      >
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            {/* Public Routes */}
            <Route
              path="/sign-in"
              element={
                <PublicRoutes>
                  <Login />
                </PublicRoutes>
              }
            />
            <Route
              path="/sign-up"
              element={
                <PublicRoutes>
                  <Signup />
                </PublicRoutes>
              }
            />
            <Route
              path="/forget-password"
              element={
                <PublicRoutes>
                  <ForgotPassword />
                </PublicRoutes>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoutes>
                  <Home />
                </PrivateRoutes>
              }
            />
            <Route
              path="/product-list"
              element={
                <PrivateRoutes>
                  <ProductList />
                </PrivateRoutes>
              }
            />

            <Route
              path="/cart"
              element={
                <PrivateRoutes>
                  <Cart />
                </PrivateRoutes>
              }
            />
            <Route
              path="/search"
              element={
                <PrivateRoutes>
                  <SearchPage />
                </PrivateRoutes>
              }
            />

            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />

            {/* Customer Routes  */}
            <Route path="/profile" element={<CustomersProfile />} />
            <Route path="/orders" element={<CustomersOrders />} />
            <Route path="/order/:id" element={<CustomerOrderDetails />} />
            <Route path="/manage-address" element={<CustomerMangeAddress />} />
            <Route path="/edit-profile" element={<CustomerEditProfile />} />

            {/* Admin Routes  */}
            <Route
              path="/dashboard"
              element={
                <AdminRoutes>
                  <Dashboard />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/customer-analytics"
              element={
                <AdminRoutes>
                  <UserAnalytics />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/product-analytics"
              element={
                <AdminRoutes>
                  <ProductAnalytics />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-product"
              element={
                <AdminRoutes>
                  <Product />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-product/new"
              element={
                <AdminRoutes>
                  <AddProduct />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-categories"
              element={
                <AdminRoutes>
                  <Category />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-categories/new"
              element={
                <AdminRoutes>
                  <AddCategory />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-categories/edit/:category_id"
              element={
                <AdminRoutes>
                  <EditCategory />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-sub-category/:category_id"
              element={
                <AdminRoutes>
                  <SubCategory />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-sub-category/new"
              element={
                <AdminRoutes>
                  <AddSubCategory />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-sub-categories/edit/:subcategory_id"
              element={
                <AdminRoutes>
                  <EditSubCategory />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/manage-product/edit/:product_id"
              element={
                <AdminRoutes>
                  <EditProduct />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/all-orders"
              element={
                <AdminRoutes>
                  <AllOrders />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/order-details/:order_id"
              element={
                <AdminRoutes>
                  <OrderDetails />
                </AdminRoutes>
              }
            />
            <Route
              path="/dashboard/settings"
              element={
                <AdminRoutes>
                  <AdminSetting />
                </AdminRoutes>
              }
            />
          </Routes>
        </AnimatePresence>
      </div>
    </>
  );
};

export default App;
