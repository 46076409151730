import React from "react";
import { Link } from "react-router-dom";

const Header = (_this) => {
  return (
    <div className="w-full flex justify-between items-center p-4 bg-stroke/70 dark:shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
      <div className="hidden sm:block">
        <div>
          <div className="relative h-full">
            <button className="absolute left-0 top-1/2 -translate-y-1/2">
              <svg
                className="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                  fill=""
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                  fill=""
                />
              </svg>
            </button>

            <input
              type="text"
              value={_this?.query}
              onChange={(e) => {
                _this?.setQuery(e.target.value);
              }}
              placeholder="Type to search..."
              className="w-full h-full bg-transparent pl-9 pr-4 text-black focus:outline-none dark:text-white xl:w-125"
            />
          </div>
        </div>
      </div>
      <div>
        <Link
          to="/dashboard/manage-categories/new"
          className="bg-dashboardPrimary hover:bg-dashboardPrimary/90 text-white py-2 px-4 rounded flex items-center gap-x-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="12"
            viewBox="0 0 18 18"
            className="mr8"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#fff" fillRule="nonzero">
                <g>
                  <g>
                    <path
                      d="M9 0c.577 0 1.052.434 1.117.994l.008.131v6.75h6.75C17.496 7.875 18 8.379 18 9c0 .577-.434 1.052-.994 1.117l-.131.008h-6.75v6.75C10.125 17.496 9.621 18 9 18c-.577 0-1.052-.434-1.117-.994l-.008-.131v-6.75h-6.75C.504 10.125 0 9.621 0 9c0-.577.434-1.052.994-1.117l.131-.008h6.75v-6.75C7.875.504 8.379 0 9 0z"
                      transform="translate(-1204 -392) translate(481 374) translate(723 18)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          Add new Category
        </Link>
      </div>
    </div>
  );
};

export default Header;
