import React from "react";
import useCartDataStore from "../../app/cartStore";
import CartNavbar from "./CartNavbar";
import CartContain from "./CartContain";
import CartPopup from "./CartPopup";
import EmptyCart from "./EmptyCart";

const Body = (_this) => {
  const { cartData } = useCartDataStore((state) => ({
    cartData: state.cartData,
  }));
  return (
    <div className="flex flex-col items-center relative">
      <div
        className={`w-full lg:min-w-[1000px] lg:max-w-[1000px] min-h-[100vh] ${
          cartData.length === 0 ? "bg-white " : "bg-cartBg/60 "
        } relative`}
      >
        <CartNavbar />
        {cartData.length === 0 ? (
          <EmptyCart />
        ) : (
          <div>
            <CartContain {..._this} />
            <CartPopup {..._this} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;
