import create from "zustand";
// import { devtools, persist } from "zustand/middleware";

const signupDataStore = (set) => ({
  signupData: null,
  setSignupData: (data) => {
    set((state) => ({
      signupData: data,
    }));
  },
  clearSignupData: () => {
    set((state) => ({
      signupData: null,
    }));
  },
});

// const useSignupDataStore = create(
//   devtools(
//     persist(signupDataStore, {
//       name: "signupData",
//     })
//   )
// );

const useSignupDataStore = create(signupDataStore);

export default useSignupDataStore;
