import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Body from "./Body";
import API from "../../api";
import useCategoryStore from "../../app/categoryStore";
import useCartDataStore from "../../app/cartStore";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io(BASE_URL);

const ProductList = () => {
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const { cartData, addToCart, increaseQuantity, decreaseQuantity } =
    useCartDataStore((state) => ({
      cartData: state.cartData,
      addToCart: state.addToCart,
      increaseQuantity: state.increaseQuantity,
      decreaseQuantity: state.decreaseQuantity,
    }));

  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [allProductsData, setAllProductsData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [category, setCategory] = useState("");

  const getAllProducts = () => {
    setLoading(true);
    API.product
      .GetAllProducts()
      .then((response) => {
        if (response) {
          setAllProductsData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategory(response[0]);
          setProductData(response[0]?.products);
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const findObjectById = (category_id, categoryArray) => {
    const category = categoryArray.find(
      (catgory) => catgory._id === category_id
    );
    setProductData(category?.products);
    setCategory(category);
  };

  // Add to Cart Functionality
  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const handleIncrease = (productId) => {
    increaseQuantity(productId);
  };

  const handleDecrease = (productId) => {
    decreaseQuantity(productId);
  };

  const isProductInCart = (productId) => {
    const product = cartData?.find((item) => item.product._id === productId);
    return product ? product.quantity : false;
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
      findObjectById(state, globalCategoryData);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, []);

  //Socket
  useEffect(() => {
    const updateCategory = (data) => {
      const categoryIndex = categoryData?.findIndex(
        (category) => category._id === data?._id
      );
      const updatedCategoryObject = [...categoryData];
      updatedCategoryObject[categoryIndex] = {
        ...updatedCategoryObject[categoryIndex],
        isAvailable: data?.isAvailable,
      };
      setCategoryData(updatedCategoryObject);
      setGlobalCategoryData(updatedCategoryObject);
      if (data?._id === category?._id) {
        setCategory(data);
      }
    };
    // Listen for category update events
    socket.on("receiving_update_category_availability", updateCategory);
    return () => {
      socket.off("receiving_update_category_availability", updateCategory);
    };
  }, [categoryData, setCategoryData]);

  useEffect(() => {
    const updateProduct = (data) => {
      const updatedCategories = [...categoryData];
      const categoryIndex = updatedCategories.findIndex(
        (cat) => cat._id === data?.product_category?._id
      );

      const productIndex = updatedCategories[categoryIndex].products.findIndex(
        (prod) => prod._id === data?._id
      );

      updatedCategories[categoryIndex].products[productIndex] = {
        ...updatedCategories[categoryIndex].products[productIndex],
        is_available: data?.is_available,
      };
      setCategoryData(updatedCategories);
    };
    // Listen for category update events
    socket.on("receiving_update_product_availability", updateProduct);
    return () => {
      socket.off("receiving_update_product_availability", updateProduct);
    };
  }, [productData, setProductData]);

  const _this = {
    categoryData,
    setCategoryData,
    productData,
    setProductData,
    category,
    setCategory,
    findObjectById,
    handleAddToCart,
    handleIncrease,
    handleDecrease,
    cartData,
    isProductInCart,
    allProductsData,
    setAllProductsData,
  };

  return (
    <div className="w-[100vw] h-[100vh] overflow-y-hidden overflow-x-hidden no-scrollbar">
      <div className="no-scrollbar overflow-y-hidden">
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          // exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
        >
          <Body {..._this} />
        </motion.div>
      </div>
    </div>
  );
};

export default ProductList;
