import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Body from "./Body";
import API from "../../api";
import useCategoryStore from "../../app/categoryStore";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io(BASE_URL);

const Home = () => {
  const { globalCategoryData, setGlobalCategoryData } = useCategoryStore(
    (state) => ({
      globalCategoryData: state.globalCategoryData,
      setGlobalCategoryData: state.setGlobalCategoryData,
    })
  );
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const getAllCategory = () => {
    setLoading(true);
    API.category
      .GetAllCategory()
      .then((response) => {
        if (response) {
          setCategoryData(response);
          setGlobalCategoryData(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (globalCategoryData === null) {
      getAllCategory();
    } else {
      setCategoryData(globalCategoryData);
    }
  }, []);
  // Socket
  useEffect(() => {
    const updateCategory = (data) => {
      const categoryIndex = categoryData?.findIndex(
        (category) => category._id === data?._id
      );
      const updatedCategoryObject = [...categoryData];
      updatedCategoryObject[categoryIndex] = {
        ...updatedCategoryObject[categoryIndex],
        isAvailable: data?.isAvailable,
      };
      setCategoryData(updatedCategoryObject);
      setGlobalCategoryData(updatedCategoryObject);
    };
    // Listen for category update events
    socket.on("receiving_update_category_availability", updateCategory);
    return () => {
      socket.off("receiving_update_category_availability", updateCategory);
    };
  }, [categoryData, setCategoryData]);

  const _this = {
    categoryData,
    setCategoryData,
  };

  return <Body {..._this} />;
};

export default Home;
