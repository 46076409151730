import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CategoryList = (_this) => {
  const breakpoints = {
    275: {
      slidesPerView: 3,
    },
    300: {
      slidesPerView: 3,
    },

    370: {
      slidesPerView: 4,
    },

    480: {
      slidesPerView: 5,
    },

    640: {
      slidesPerView: 6,
    },

    768: {
      slidesPerView: 7,
    },

    1024: {
      slidesPerView: 7,
    },

    1280: {
      slidesPerView: 7,
    },
    1536: {
      slidesPerView: 7,
    },
    1700: {
      slidesPerView: 7,
    },
  };
  return (
    <>
      <div className="category-section w-full mb-1">
        <div className="p-[16px]">
          <div className="mb-[20px] flex items-center overflow-hidden">
            <div className=" text-[11px] text-nowrap leading-[16px] tracking-widest uppercase text-[#02060cbf] font-[600] font-gilroy-bold">
              Shop by Category
            </div>
            <div className="h-[1px] bg-custom-gradient w-[100%] ml-[12px] align-middle"></div>
            <div className="flex gap-x-2">
              <button className="swiper-button-prev-custom bg-cartBg/50 p-2 sm:p-2.5 rounded-full">
                <IoMdArrowBack />
              </button>
              <button className="swiper-button-next-custom bg-cartBg/50 p-2 sm:p-2.5 rounded-full">
                <IoMdArrowForward />
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <Swiper
              slidesPerView={4}
              spaceBetween={7}
              navigation={{
                nextEl: ".swiper-button-next-custom",
                prevEl: ".swiper-button-prev-custom",
              }}
              breakpoints={breakpoints}
              modules={[Navigation]}
              className="mySwiper"
            >
              {_this?.categoryData &&
                _this?.categoryData.map((category, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex justify-center max-w-maxContent">
                      <Link
                        to={`/product-list`}
                        state={category?._id}
                        className="flex flex-col justify-center items-center"
                        key={index}
                      >
                        <div className="h-[71px] w-[62px] xxs:h-[97px] xxs:w-[86px] xs:h-[110px] xs:w-[98px]">
                          <img
                            src={`${BASE_URL}${category?.category_image}`}
                            alt="vegitable"
                            className={`h-full w-full ${
                              !category?.isAvailable && "grayscale"
                            }`}
                          />
                        </div>
                        <div className="text-[13px] text-center font-gilroy-semibold font-[600] tracking-tight leading-4 text-text_dark opacity-[75%] pt-[8px] w-[100%] truncate">
                          {category?.category_name}
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
