import React, { useState } from "react";
import Body from "./Body";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import toast from "react-hot-toast";
import { setCookie } from "../../utils";

const CustomerEditProfile = () => {
  const { authData, setAuthData } = useAuthDataStore((state) => ({
    authData: state.authData,
    setAuthData: state.setAuthData,
  }));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [OTP, setOTP] = useState("");
  const onChangeCustomerDetails = (formValue) => {
    console.log(formValue);
    setButtonLoading(true);

    API.auth
      .UpdateCustomerDetails(authData.token, formValue)
      .then((response) => {
        if (response) {
          const data = {
            token: authData.token,
            userData: response,
          };
          setAuthData(data);
          setCookie(
            "authentications",
            { token: authData.token, userData: response },
            7
          );
          toast.success("Name Updated");
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const onChangeEmail = (formValue) => {
    if (otpReceived === false) {
      const data = {
        email: formValue.email,
      };
      setButtonLoading(true);
      API.auth
        .UpdateEmail(authData.token, data)
        .then((response) => {
          if (response) {
            if (response.otp) {
              setOtpReceived(true);
              toast.success("OTP has been set to your email account.");
            }
          }
        })
        .finally(() => {
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(true);
      const data = {
        ...formValue,
        otp: OTP,
      };

      API.auth
        .UpdateEmail(authData.token, data)
        .then((response) => {
          if (response) {
            const cookieData = {
              token: authData.token,
              userData: response,
            };
            setAuthData(cookieData);
            setCookie(
              "authentications",
              { token: authData.token, userData: response },
              7
            );
            setOtpReceived(false);
            toast.success("Email Updated");
          }
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  const _this = {
    buttonLoading,
    setButtonLoading,
    otpReceived,
    setOtpReceived,
    OTP,
    setOTP,
    onChangeCustomerDetails,
    onChangeEmail,
  };
  return <Body {..._this} />;
};

export default CustomerEditProfile;
