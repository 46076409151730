import React from "react";
import CustomersTable from "./CustomersTable";

const Body = (_this) => {
  return (
    <>
      <CustomersTable {..._this} />
    </>
  );
};

export default Body;
