import create from "zustand";

const categoryStore = (set) => ({
  globalCategoryData: null,
  setGlobalCategoryData: (data) => {
    set((state) => ({
      globalCategoryData: data,
    }));
  },
});

const useCategoryStore = create(categoryStore);

export default useCategoryStore;
