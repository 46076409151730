import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Body from "./Body";
import API from "../../api";
import Loader from "../../common/Loader";

// Zustand
import useSignupDataStore from "../../app/signupDataStore";

const Signup = () => {
  const navigate = useNavigate();
  const { signupData, setSignupData, clearSignupData } = useSignupDataStore(
    (state) => ({
      signupData: state.signupData,
      setSignupData: state.setSignupData,
      clearSignupData: state.clearSignupData,
    })
  );

  const [loading, setLoading] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [OTP, setOTP] = useState("");
  const [hostelsOptions, setHostelOptions] = useState([]);

  const onSignUp = async (formValue) => {
    setLoading(true);
    API.auth
      .SendOtp(formValue)
      .then((response) => {
        if (response) {
          if (response.otp) {
            setOtpReceived(true);
            setSignupData(formValue);
            toast.success("OTP has been set to your email account.");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOtpSubmit = () => {
    setLoading(true);
    const data = {
      ...signupData,
      otp: OTP,
    };

    API.auth
      .SignUp(data)
      .then((response) => {
        if (response) {
          clearSignupData();
          toast.success("Sign Up Sucessfully");
          navigate("/sign-in");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onResendOTPSubmit = () => {
    // const data = {
    //   email: signupData.email,
    // };
    // console.log(data);
    // dispatch(sendOtp(data, setOtpReceived, setFormValue));
  };

  const getTotalHostel = () => {
    API.auth
      .GetHostels()
      .then((response) => {
        if (response) {
          setHostelOptions(response?.hostels);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    getTotalHostel();
  }, []);

  const _this = {
    otpReceived,
    setOtpReceived,
    onSignUp,
    onOtpSubmit,
    OTP,
    setOTP,
    onResendOTPSubmit,
    hostelsOptions,
    setHostelOptions,
  };
  return <>{loading ? <Loader /> : <Body {..._this} />}</>;
};

export default Signup;
