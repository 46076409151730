import React, { useState } from "react";
import Body from "./Body";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import { setCookie } from "../../utils";

const CustomerMangeAddress = () => {
  const { authData, setAuthData } = useAuthDataStore((state) => ({
    authData: state.authData,
    setAuthData: state.setAuthData,
  }));
  const [buttonLoading, setButtonLoading] = useState(false);
  const onSaveAddress = (formValue) => {
    setButtonLoading(true);
    API.auth
      .UpdateAddress(authData.token, formValue)
      .then((response) => {
        if (response) {
          const data = {
            token: authData.token,
            userData: response,
          };
          setAuthData(data);
          setCookie(
            "authentications",
            { token: authData.token, userData: response },
            7
          );
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const _this = {
    onSaveAddress,
    buttonLoading,
    setButtonLoading,
  };

  return <Body {..._this} />;
};

export default CustomerMangeAddress;
