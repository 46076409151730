import React from "react";

const Body = () => {
  return (
    <div className="flex gap-y-2 min-h-[calc(100vh-100px)] w-[100%] justify-center overflow-x-hidden bg-black/10">
      <div className="h-full w-11/12 max-w-maxContent mx-auto bg-white mt-6 py-4 px-6">
        <div className="flex flex-col items-center gap-y-3 ">
          <div className="text-sm">
            <h2 className="text-lg font-bold mb-4">Return Policy</h2>
            <p>
              At Kolkata Mart, we strive to deliver exceptional quality with
              every order. While we don't accept returns, your satisfaction is
              our priority.
            </p>
            <p className="mt-2">
              If you're unsatisfied with your food, please contact us at
              <span className="text-blue-400"> kolkatamart@gmail.com </span>
              within 3 hour of delivery. Our team will assess the issue.
            </p>
            <p className="mt-2">
              If the food doesn't meet our quality standards, we'll issue a
              refund. Refunds will be processed within 7-8 working days to your
              original payment method.
            </p>
            <p className="mt-4">
              Thank you for choosing Kolkata Mart. We appreciate your
              understanding and support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
