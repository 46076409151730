import React from "react";
import { useNavigate } from "react-router-dom";

const OrderPlaced = (_this) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <div className="flex items-center">
        <div className="flex flex-col items-center">
          <div className="h-[220px] w-[220px]">
            <img src="/success.gif" className="w-full h-full" />
          </div>
          <div className="pt-[16px]">
            <p className="font-gilroy-bold text-[24px] leading-7 text-[#02060ceb] -tracking-[0.4]">
              Order Placed Successfully
            </p>
            <p className="text-[#02060c99] font-gilroy-semibold text-[13px] leading-4 text-center mt-[8px]">
              Redirecting to home page in {_this?.count}
            </p>
            <div className="mt-[28px] flex justify-center">
              <button
                onClick={() => navigate("/")}
                className="bg-[#ffeee5] px-[16px] h-[44px] rounded-[12px] text-[#f15700] font-gilroy-bold text-[18px] leading-6 -tracking-[0.3]"
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPlaced;
