import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Body from "./Body";
import API from "../../api/index";
import { useParams } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";
import DefaultLayout from "../../layouts/DashboardSidebar";
import Loader from "../../common/Loader";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { order_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const getOrderById = () => {
    setLoading(true);
    API.order
      .GetOrderById(authData.token, { order_id })
      .then((response) => {
        if (response) {
          setOrderDetails(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOrderCancel = (formValue) => {
    console.log(cancelOrderModal);
    console.log(formValue);
    let formData = new FormData();
    formData.append("order_id", cancelOrderModal);
    formData.append("cancellation_reason", formValue.cancellation_reason);
    setButtonLoading(true);
    API.order
      .AdminOrderCancel(authData.token, formData)
      .then((response) => {
        if (response) {
          setCancelOrderModal(false);
          navigate("/dashboard/all-orders");
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    getOrderById();
  }, []);

  const _this = {
    loading,
    setLoading,
    orderDetails,
    setOrderDetails,
    cancelOrderModal,
    setCancelOrderModal,
    handleOrderCancel,
    buttonLoading,
    setButtonLoading,
  };
  return (
    <DefaultLayout>
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default OrderDetails;
