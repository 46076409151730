import React from "react";
import { Steps, ConfigProvider } from "antd";
import { FiShoppingBag } from "react-icons/fi";
import { LuPackageOpen } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import { TbBasketCancel } from "react-icons/tb";
import moment from "moment";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const OrderDetails = (_this) => {
  const statusArray = ["Ordered", "Packed", "Delivered", "Cancelled"];
  return (
    <div className="flex flex-col gap-y-7 h-full">
      <div>
        <div className="flex justify-start">
          <div className="mb-[12px] ml-[4px]">
            <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
              Order Status
            </p>
          </div>
        </div>
        <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
          <div className="py-[16px] gap-[16px] flex flex-col">
            <div className="flex flex-col gap-y-2 pt-1 font-light text-black/70 px-7">
              {_this?.order?.order_status === "Cancelled" && (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#ed1c24",
                    },
                  }}
                >
                  <Steps
                    items={[
                      {
                        title: (
                          <div className="font-gilroy-semibold">Ordered</div>
                        ),
                        description: (
                          <div className="font-gilroy-medium text-xs">
                            You have just Ordered.
                          </div>
                        ),
                        icon: <FiShoppingBag />,
                      },
                      {
                        title: (
                          <div className="font-gilroy-semibold">Cancelled</div>
                        ),
                        description: (
                          <div className="font-gilroy-medium text-xs">
                            Your order is Cancelled.
                          </div>
                        ),
                        icon: <TbBasketCancel />,
                      },
                    ]}
                  />
                </ConfigProvider>
              )}
              {_this?.order?.order_status !== "Cancelled" && (
                <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#ed1c24",
                      },
                    }}
                  >
                    <Steps
                      current={statusArray.indexOf(_this.order?.order_status)}
                      items={[
                        {
                          title: (
                            <div className="font-gilroy-semibold">Ordered</div>
                          ),
                          description: (
                            <div className="font-gilroy-medium text-xs">
                              You have just Ordered.
                            </div>
                          ),
                          icon: <FiShoppingBag />,
                        },
                        {
                          title: (
                            <div className="font-gilroy-semibold">Packed</div>
                          ),
                          description: (
                            <div className="font-gilroy-medium text-xs">
                              Your order is packed.
                            </div>
                          ),
                          icon: <LuPackageOpen />,
                        },
                        {
                          title: (
                            <div className="font-gilroy-semibold">
                              Delivered
                            </div>
                          ),
                          description: (
                            <div className="font-gilroy-medium text-xs">
                              Out for Delivery.
                            </div>
                          ),
                          icon: <TbTruckDelivery />,
                        },
                      ]}
                    />
                  </ConfigProvider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-start">
          <div className="mb-[12px] ml-[4px]">
            <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
              Products
            </p>
          </div>
        </div>
        <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
          <div className="py-[16px] gap-[16px] flex flex-col">
            <div className="gap-[16px] flex flex-col">
              {_this.order?.order_items?.map((item, index) => {
                return (
                  <div className="px-[12px] flex items-center" key={index}>
                    <div className="w-[44px] h-[44px] rounded-[4px] overflow-hidden bg-[#f0f0f5]">
                      <img
                        src={`${BASE_URL}${item?.product?.product_image}`}
                        alt="cart_image"
                        className="h-full w-full object-fill"
                      />
                    </div>
                    <div className="flex-1 ml-[12px] mr-[8px] overflow-hidden">
                      <p className="text-[14px] font-gilroy-semibold leading-4 font-normal text-[#02060cbf] tracking-[-0.3px]">
                        {item?.product?.product_name}
                      </p>
                      <p className="text-[10px] font-gilroy-semibold leading-3 font-normal text-[#02060c73] tracking-[-0.25px]">
                        {item?.product?.product_description1}
                      </p>
                    </div>
                    <div className="h-[32px] w-[50px] flex flex-col justify-end items-end">
                      <p className="rs line-through mt-[1px] font-gilroy-semibold text-[10px] leading-3 tracking-[-0.26px] text-[#02060c73]">
                        {item?.product?.product_price}
                      </p>
                      <p className="rs mt-[1px] font-gilroy-bold text-[14px] leading-[18px] font-semibold tracking-[-0.3px] text-[#02060cbf]">
                        {item?.product?.discounted_price}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-start">
          <div className="mb-[12px] ml-[4px]">
            <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
              Bill Details
            </p>
          </div>
        </div>
        <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
          <div className="p-[16px] gap-[16px] flex flex-col">
            <div className="gap-[16px] flex flex-col">
              <div className="flex items-center justify-between mb-[12px]">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  MRP Total
                </div>
                <div className="rs text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {_this?.order?.total_amount}
                </div>
              </div>
              <div className="flex items-center justify-between pb-[16px] border-b border-dashed">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Item Saving
                </div>
                <div className="-rs text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#1ba672] break-words">
                  {_this?.order?.total_amount - _this?.order?.discounted_amount}
                </div>
              </div>
              <div className=" flex items-center justify-between pt-[12px]">
                <div className="text-[14px] font-gilroy-bold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Total Pay
                </div>
                <div className="flex items-center gap-x-1">
                  <p className="rs text-[13px] line-through font-gilroy-medium leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                    {_this?.order?.total_amount}
                  </p>
                  <p className="rs text-[14px] font-gilroy-bold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                    {_this?.order?.discounted_amount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-start">
          <div className="mb-[12px] ml-[4px]">
            <p className="text-[16px] font-gilroy-bold leading-5 font-bold tracking-[-0.3px] text-[#02060cbf]">
              Order Details
            </p>
          </div>
        </div>
        <div className="transform z-1px bg-white rounded-[16px] shadow-custom">
          <div className="p-[16px] gap-[16px] flex flex-col">
            <div className="gap-[16px] flex flex-col">
              <div className="flex items-center justify-between mb-[12px]">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Order Id:
                </div>
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {_this.order?.order_id}
                </div>
              </div>
              <div className="flex items-center justify-between pb-[16px]">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Payment:
                </div>
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {_this.order?.payment_mod === "COD"
                    ? "Cash on Delivery"
                    : "Online"}
                </div>
              </div>
              <div className="flex items-center justify-between pb-[16px]">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Delivey to:
                </div>
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {_this.order?.user?.room_number}/
                  {_this.order?.user?.floor_number}
                </div>
              </div>

              <div className="flex items-center justify-between pb-[16px]">
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  Order Placed:
                </div>
                <div className="text-[14px] font-gilroy-semibold leading-4 tracking-[-0.3px] text-[#02060cbf] break-words">
                  {moment(_this.orderData?.order_date).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
