import React from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SerachResult = (_this) => {
  return (
    <div className="category-section w-full mb-20">
      <div className="p-[16px]">
        <div className="mb-[20px] flex items-center overflow-hidden">
          <div className=" text-[11px] text-nowrap leading-[16px] tracking-widest uppercase text-[#02060cbf] font-[600] font-gilroy-bold">
            Search Result
          </div>
          <div className="h-[1px] bg-custom-gradient w-[100%] ml-[12px] align-middle"></div>
        </div>
        <div className="flex justify-center items-center">
          <div>
            {_this?.filteredItems.length === 0 ? (
              <p className="text-[13px] leading-[16px] font-gilroy-medium tracking-wider font-[200] text-text_secondary">
                Search to show items
              </p>
            ) : (
              <div className="grid grid-cols-2 xxs:grid-cols-3 sm:grid-cols-4 xlg:grid-cols-5">
                {_this?.filteredItems.length > 0 &&
                  _this?.filteredItems?.map((product, index) => {
                    return (
                      <div className="relative p-[8px]" key={index}>
                        <div className="flex items-center justify-center py-5">
                          <div className=" w-[120px] h-[120px] ">
                            <img
                              src={`${BASE_URL}${product?.product_image[0]}`}
                              alt="product_image"
                              className={`h-full w-full ${
                                (!product.universal_availability ||
                                  !product?.product_category?.isAvailable ||
                                  !product?.is_available) &&
                                "grayscale"
                              }`}
                            />
                          </div>
                        </div>
                        <div className="mt-2 truncate font-gilroy-bold font-bold text-[14px] leading-[18px] tracking-[-0.3px] text-[rgba(2,6,12,0.75)] overflow-hidden w-full break-words min-h-[36px]">
                          {product?.product_name}
                        </div>
                        <div className="font-gilroy-medium text-[rgba(2,6,12,0.75)] block text-[12px] leading-[16px] p-0 border-0 outline-none overflow-hidden whitespace-nowrap ">
                          {product?.product_description1}
                        </div>
                        <div className="mt-[8px] flex justify-between">
                          <div className="flex flex-col justify-center">
                            <span className="text-[10px] font-gilroy-light rs line-through">
                              {product?.product_price}
                            </span>
                            <p className="rs font-gilroy-bold font-bold text-[14px] leading-[18px] tracking-[-0.3px] text-[rgba(2,6,12,0.75)] overflow-hidden w-full break-words ">
                              {product?.discounted_price}
                            </p>
                          </div>
                          <div className="flex items-center transition-container">
                            {_this?.isProductInCart(product?._id) ? (
                              <div className="w-[76px] border py-2 rounded-lg shadow-1 flex justify-around items-center">
                                <button
                                  onClick={() =>
                                    _this?.handleDecrease(product?._id)
                                  }
                                  className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                                >
                                  -
                                </button>
                                <div className="text-[14px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]">
                                  {_this?.isProductInCart(product?._id)}
                                </div>
                                <button
                                  onClick={() =>
                                    _this?.handleIncrease(product?._id)
                                  }
                                  className="text-[15px] font-gilroy-bold leading-4 font-normal text-[#1ba672] tracking-[-0.3px]"
                                >
                                  +
                                </button>
                              </div>
                            ) : (
                              <div>
                                {!product.universal_availability ||
                                !product?.product_category?.isAvailable ||
                                !product?.is_available ? (
                                  <div className="">
                                    <p
                                      className={` font-gilroy-semibold text-[12px] leading-[17px] tracking-[-0.1px] text-[rgba(2,6,12,0.75)]`}
                                    >
                                      Not Available
                                    </p>
                                  </div>
                                ) : (
                                  <button
                                    onClick={() =>
                                      _this?.handleAddToCart(product)
                                    }
                                    className="w-[76px] border py-2 rounded-lg shadow-1 hover:bg-strokedark/10"
                                  >
                                    <p
                                      className={`font-gilroy-bold text-[14px] leading-[17px] tracking-[-0.1px] text-[#1ba672]`}
                                    >
                                      Add
                                    </p>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerachResult;
