import { apiConnector, handleResponse } from "./core";
import { analyticsEndpoints } from "./apis";

const {
  GET_ANALYTICS_API,
  GET_CATEGORY_ANALYTICS_API,
  GET_ORDER_ANALYTICS_API,
  GET_TOP_ORDERING_CUSTOMERS_API,
  GET_PRODUCT_ORDER_COUNT_PER_MONTH,
  GET_TOP_SELLING_PRODUCT_API,
} = analyticsEndpoints;

const analytics = {
  GetAnalytics: async (token) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_ANALYTICS_API, null, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  GetCategoryAnalytics: async (token) => {
    let response = null;
    try {
      response = await apiConnector("GET", GET_CATEGORY_ANALYTICS_API, null, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  GetOrderAnalytics: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", GET_ORDER_ANALYTICS_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetTopOrderingUsers: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "POST",
        GET_TOP_ORDERING_CUSTOMERS_API,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetProductOrderCountPerMonth: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector(
        "POST",
        GET_PRODUCT_ORDER_COUNT_PER_MONTH,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  GetTopSellingProduct: async (token, data) => {
    let response = null;
    try {
      response = await apiConnector("POST", GET_TOP_SELLING_PRODUCT_API, data, {
        Authorization: `Bearer ${token}`,
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
};

export default analytics;
