import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "../../common/Loader";
import Body from "./Body";
import DefaultLayout from "../../layouts/DashboardSidebar";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";

const ProductAnalytics = () => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [productOrderCount, setProductOrderCount] = useState([
    {
      name: "Amount",
      type: "column",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Product",
      type: "line",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [productData, setProductData] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const getProductOrderCountPerMonth = (_id) => {
    setButtonLoading(true);
    API.analytics
      .GetProductOrderCountPerMonth(authData.token, {
        product_id: _id,
      })
      .then((response) => {
        if (response) {
          setProductOrderCount(response);
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const getTopSellingProduct = (startDate, endDate, reset) => {
    setLoading(true);
    let data = {};
    if (startDate !== null && startDate !== null && !reset) {
      data = {
        start_date: startDate,
        end_date: endDate,
      };
    }
    API.analytics
      .GetTopSellingProduct(authData.token, data)
      .then((response) => {
        if (response) {
          setSelectedProduct(response[0]._id);
          getProductOrderCountPerMonth(response[0]._id);
          setProductData(response);
          setTopSellingProducts(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProductAnalytics = (_id) => {
    setSelectedProduct(_id);
    getProductOrderCountPerMonth(_id);
  };

  useEffect(() => {
    getTopSellingProduct(null, null, null);
  }, []);

  const _this = {
    loading,
    setLoading,
    productOrderCount,
    setProductOrderCount,
    topSellingProducts,
    setTopSellingProducts,
    productData,
    setProductData,
    getProductAnalytics,
    selectedProduct,
    setSelectedProduct,
    buttonLoading,
    setButtonLoading,
    getTopSellingProduct,
  };

  return (
    <DefaultLayout>
      <Header {..._this} />
      {loading ? <Loader dashboard={true} /> : <Body {..._this} />}
    </DefaultLayout>
  );
};

export default ProductAnalytics;
