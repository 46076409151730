import moment from "moment";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { TbFileInvoice } from "react-icons/tb";
import { FcCancel } from "react-icons/fc";
import OrderCancelModal from "./OrderCancelModal";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Body = (_this) => {
  const PagePdfRef = useRef();
  return (
    <>
      <div ref={PagePdfRef} className="p-4 md:p-6 2xl:p-10">
        <div className="flex flex-col gap-3">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark flex justify-between items-center">
              <div>
                <h3 className="font-medium text-black dark:text-white font-satoshi">
                  Order Id : {_this?.orderDetails?.order_id}
                </h3>
                <p className="text-sm text-strokedark/60">
                  {moment(_this?.orderDetails?.order_date).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-2">
                <ReactToPrint
                  trigger={() => (
                    <button className="bg-dashboardPrimary py-1 px-2 sm:py-2 sm:px-3 text-white text-sm sm:text-base font-satoshi flex items-center gap-x-1 border border-dashboardPrimary">
                      <TbFileInvoice /> Invoice
                    </button>
                  )}
                  content={() => PagePdfRef.current}
                />
                <button
                  onClick={() =>
                    _this?.setCancelOrderModal(_this?.orderDetails?._id)
                  }
                  className="bg-white py-1 px-2 sm:py-2 sm:px-3 text-sm sm:text-base text-danger border border-danger  font-satoshi flex items-center gap-x-1 hover:bg-danger/10 transition-all duration-200"
                >
                  <FcCancel /> Cancel
                </button>
              </div>
            </div>

            <div className="p-3">
              <div className="py-[16px] gap-[16px] flex flex-col">
                <div className=" flex flex-col divide-y-[1px]">
                  {_this?.orderDetails?.order_items?.map((item, index) => {
                    return (
                      <div
                        className="px-[12px] flex items-center py-3"
                        key={index}
                      >
                        <div className="w-[60px] h-[60px] rounded-[4px] overflow-hidden bg-[#f0f0f5]">
                          <img
                            src={`${BASE_URL}${item?.product?.product_image}`}
                            alt="order_items"
                            className="h-full w-full object-fill"
                          />
                        </div>
                        <div className="flex-1 ml-[12px] mr-[8px] overflow-hidden">
                          <p className="text-[14px] font-satoshi">
                            {item?.product?.product_name}
                          </p>
                          <p className="text-[10px] font-satoshi">
                            {item?.product?.product_description1}
                          </p>
                        </div>
                        <div className="text-[14px] font-satoshi">
                          Quantity : {item?.quantity}
                        </div>
                        <div className="h-[32px] w-[50px] flex flex-col justify-end items-end">
                          <p className="rs line-through mt-[1px] font-satoshi  text-[10px]">
                            {item?.product?.product_price}
                          </p>
                          <p className="rs mt-[1px]  text-[14px] font-satoshi ">
                            {item?.product?.discounted_price}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="p-3 border-t">
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold">Total Amount:</p>
                    <div>
                      <p className="rs line-through mt-[1px] font-satoshi  text-[12px]">
                        {_this?.orderDetails?.total_amount}
                      </p>
                      <p className="rs mt-[1px] font-satoshi  text-[15px]">
                        {_this?.orderDetails?.discounted_amount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark flex justify-between items-center">
              <div>
                <h3 className="font-medium text-black dark:text-white font-satoshi py-2">
                  Customer Details
                </h3>
              </div>
            </div>

            <div className="p-6 flex flex-col gap-y-2">
              <div className="flex flex-col gap-2 md:flex-row justify-between">
                <div className="">
                  <p className="font-satoshi font-semibold">Name:</p>
                  <p className="font-satoshi">
                    {_this?.orderDetails?.user?.name}
                  </p>
                </div>
                <div>
                  <p className="font-satoshi font-semibold">Phone:</p>
                  <p className="font-satoshi">
                    {_this?.orderDetails?.user?.phone_number}
                  </p>
                </div>
                <div>
                  <p className="font-satoshi font-semibold">Email:</p>
                  <p className="font-satoshi">
                    {_this?.orderDetails?.user?.email}
                  </p>
                </div>
              </div>
              <div>
                <p className="font-satoshi font-semibold">Shipping Address:</p>
                <p className="font-satoshi font-normal">
                  <span>{_this?.orderDetails?.user?.room_number}</span>/
                  <span>{_this?.orderDetails?.user?.floor_number}</span>,
                  <span>{_this?.orderDetails?.user?.hostel_name}</span>,
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {_this?.cancelOrderModal && <OrderCancelModal {..._this} />}
    </>
  );
};

export default Body;
