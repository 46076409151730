import React from "react";
import { Navigate } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";

const PublicRoutes = ({ children }) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));

  if (authData === null) {
    return children;
  } else if (
    authData !== null &&
    authData?.userData?.account_type === "Admin"
  ) {
    return <Navigate to="/dashboard" />;
  } else return <Navigate to="/" />;
};

export default PublicRoutes;
