import React from "react";
import { Navigate } from "react-router-dom";
import useAuthDataStore from "../../app/authStore";

const PrivateRoutes = ({ children }) => {
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));

  if (
    authData === null ||
    (authData !== null && authData?.userData?.account_type === "Customer")
  ) {
    return children;
  } else if (
    authData !== null &&
    authData?.userData?.account_type === "Admin"
  ) {
    return <Navigate to="/dashboard" />;
  }
};

export default PrivateRoutes;
