import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../api";
import useAuthDataStore from "../../app/authStore";
import useDashboardLoading from "../../app/dashboardLoading";
import toast from "react-hot-toast";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Body = (_this) => {
  const { subcategory_id } = useParams();
  const { authData } = useAuthDataStore((state) => ({
    authData: state.authData,
  }));
  const { setLoading } = useDashboardLoading((state) => ({
    setLoading: state.setLoading,
  }));
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    _this?.subCategoryDetails?.product_category?._id
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const fileProps = {
    action: "",
    name: "file",
    multiple: true,
    customRequest(e) {
      e.onSuccess();
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        category_name: "",
        category_description: "",
      });
    }
  }, [reset, isSubmitSuccessful]);

  /*==================================
                Create Category
        =====================================
        */
  const onUpdateSubCategory = (formValue) => {
    let formData = new FormData();
    formData.append("subcategory_id", subcategory_id);
    formData.append("subcategory_name", formValue.subcategory_name);
    formData.append(
      "subcategory_description",
      formValue.subcategory_description
    );
    formData.append("product_category_id", selectedCategoryId);
    if (fileList.length > 0) {
      formData.append("subCategory_image_file", fileList[0]?.originFileObj);
    }

    setLoading(true);
    API.subCategory
      .UpdateSubCategory(authData.token, formData)
      .then((response) => {
        if (response) {
          toast.success("Sub Category Updated successfully");
          navigate(-1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="p-4 md:p-6 2xl:p-10">
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(onUpdateSubCategory)}
        >
          {/* <!-- Input Fields --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark font-satoshi">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Sub Category Information
              </h3>
              <p className="text-sm text-strokedark/60">
                Easily input essential details like name, and more to showcase
                your sub category.
              </p>
            </div>

            {/* <div>
              <div className="flex flex-col gap-5 p-6">
                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Sub Category Name <span className="text-meta-1">*</span>
                    {errors.subcategory_name && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Sub Category name is required.
                      </span>
                    )}
                  </label>
                  <input
                    defaultValue={_this?.subCategoryDetails?.subcategory_name}
                    type="text"
                    placeholder="Sub Category Name"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("subcategory_name", {
                      value: _this?.subCategoryDetails?.subcategory_name,
                      required: true,
                    })}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="product_category"
                    className="mb-2.5 block text-black dark:text-white"
                  >
                    Category <span className="text-meta-1">*</span>
                    {errors.product_category && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Category is required.
                      </span>
                    )}
                  </label>

                  <div className="relative z-20 bg-transparent dark:bg-form-input">
                    <select
                      name="product_category_id"
                      {...register("product_category_id", {
                        value: selectedCategoryId,
                        required: true,
                      })}
                      onChange={(e) => {
                        setValue("product_category_id", e.target.value);
                        setSelectedCategoryId(e.target.value);
                      }}
                      className={`relative z-20 w-full appearance-none border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white`}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-body dark:text-bodydark"
                      >
                        Select your Category
                      </option>
                      {_this?.categoryData &&
                        _this?.categoryData.map((category, index) => {
                          return (
                            <option
                              key={index}
                              value={category?._id}
                              className="text-body dark:text-bodydark"
                            >
                              {category?.category_name}
                            </option>
                          );
                        })}
                    </select>

                    <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                      <svg
                        className="fill-current"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                            fill=""
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Sub Category Description{" "}
                    <span className="text-meta-1">*</span>
                    {errors.subcategory_description && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Description is required.
                      </span>
                    )}
                  </label>
                  <textarea
                    defaultValue={
                      _this?.subCategoryDetails?.subcategory_description
                    }
                    rows={6}
                    placeholder="Type your category description"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("subcategory_description", {
                      value: _this?.subCategoryDetails?.subcategory_description,
                      required: true,
                    })}
                  ></textarea>
                </div>
                <div>
                  <label
                    htmlFor="product_category"
                    className="mb-2.5 block text-black dark:text-white"
                  >
                    Category <span className="text-meta-1">*</span>
                    {errors.product_category && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Product Category is required.
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div> */}
            <div>
              <div className="flex flex-col gap-5 p-6">
                <div>
                  <label className="mb-3 block text-black dark:text-white">
                    Sub Category Name <span className="text-meta-1">*</span>
                    {errors.subcategory_name && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Sub Category name is required.
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    defaultValue={_this?.subCategoryDetails?.subcategory_name}
                    placeholder="Category Name"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("subcategory_name", {
                      value: _this?.subCategoryDetails?.subcategory_name,
                      required: true,
                    })}
                  />
                </div>

                {/* <div className="mb-6">
                  <label
                    htmlFor="product_category"
                    className="mb-2.5 block text-black dark:text-white"
                  >
                    Category <span className="text-meta-1">*</span>
                    {errors.product_category && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Category is required.
                      </span>
                    )}
                  </label>

                  <div className="relative z-20 bg-transparent dark:bg-form-input">
                    <select
                      name="product_category_id"
                      {...register("product_category_id", {
                        value: selectedCategoryId,
                      })}
                      onChange={(e) => {
                        setValue("product_category_id", e.target.value);
                        setSelectedCategoryId(e.target.value);
                      }}
                      className={`relative z-20 w-full appearance-none border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white`}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-body dark:text-bodydark"
                      >
                        Select your Category
                      </option>
                      {_this?.categoryData &&
                        _this?.categoryData.map((category, index) => {
                          return (
                            <option
                              key={index}
                              value={category?._id}
                              className="text-body dark:text-bodydark"
                            >
                              {category?.category_name}
                            </option>
                          );
                        })}
                    </select>

                    <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                      <svg
                        className="fill-current"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                            fill=""
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div> */}

                <div className="mb-6">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Category Description <span className="text-meta-1">*</span>
                    {errors.subcategory_description && (
                      <span className="-mt-1 text-[12px] text-meta-1 font-gilroy-light">
                        Description is required.
                      </span>
                    )}
                  </label>
                  <textarea
                    rows={6}
                    defaultValue={
                      _this?.subCategoryDetails?.subcategory_description
                    }
                    placeholder="Type your category description"
                    className="w-full border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-dashboardPrimary active:border-dashboardPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-dashboardPrimary"
                    {...register("subcategory_description", {
                      value: _this?.subCategoryDetails?.subcategory_description,
                      required: true,
                    })}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Sub Category Images <span className="text-meta-1">*</span>
              </h3>
              <p className="text-sm text-strokedark/60">
                Upload captivating images and videos to make your category stand
                out.
              </p>
            </div>

            <div>
              <div className="flex flex-col gap-5 p-6">
                <Upload
                  action="#"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  {...fileProps}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button className="flex justify-center text-white bg-dashboardPrimary p-3 font-medium text-gray hover:bg-opacity-90">
              Edit Sub Category
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Body;
