import analytics from "./analytics";
import auth from "./auth";
import category from "./category";
import order from "./order";
import product from "./product";
import subCategory from "./subCategory";

const API = {
  auth,
  category,
  subCategory,
  product,
  order,
  analytics,
};

export default API;
