import React from "react";
import Header from "./Header";
import CategoryTable from "./CategoryTable";
import DeleteModal from "./DeleteModal";

const Body = (_this) => {
  return (
    <>
      <CategoryTable {..._this} />
      <DeleteModal {..._this} />
    </>
  );
};

export default Body;
