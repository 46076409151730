import create from "zustand";

const productStore = (set) => ({
  globalProductData: null,
  setGlobalProductData: (data) => {
    set((state) => ({
      globalProductData: data,
    }));
  },
});

const useProductStore = create(productStore);

export default useProductStore;
