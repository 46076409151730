import React from "react";
import { useNavigate } from "react-router-dom";
import Body from "./Body";
import API from "../../api/index";
import useAuthDataStore from "../../app/authStore";

const CustomersProfile = () => {
  const { clearAuthData } = useAuthDataStore((state) => ({
    clearAuthData: state.clearAuthData,
  }));
  const navigate = useNavigate();

  const logOut = () => {
    API.auth.Logout(navigate, clearAuthData);
  };
  const _this = {
    logOut,
  };
  return <Body {..._this} />;
};

export default CustomersProfile;
